import Api from "config/api";

export const verifyRequest = async ({ email, otp }) => {
  try {
    const request = await Api.post("/api/users/password/reset/verify", {
      email: email,
      otp: otp,
    });
    // console.log("request: ", request);
    return request;
  } catch (error) {
    return error.response;
  }
};

// Resend the opt

export const resendOtp = async ({ email }) => {
  try {
    const request = await Api.post("/api/users/password/reset/request", {
      email: email,
    });
    // console.log("request: ", request);
    return request;
  } catch (error) {
    return error.response;
  }
};
