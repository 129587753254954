import DeleteIcon from "@mui/icons-material/Delete";
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Pagination,
  Select,
  Stack,
  TextField,
} from "@mui/material";
import { useEffect, useState } from "react";
import { Card, CardHeader, Container, Row, Table } from "reactstrap";
// core components
import AddCircleIcon from "@mui/icons-material/AddCircle";
import EditIcon from "@mui/icons-material/Edit";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import BreadCrumbs from "components/BreadCrumbs";
import GlobalModal from "components/GlobalModal/GlobalModal";
import AddModel from "components/Modal/AddParentModal";
import CloseModel from "components/Modal/DeleteDialouge";
import useThemeMode from "hooks/useThemeMode";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { notifyError, notifySuccess } from "utilites/toastify";
import { AddBranch, DeleteBranch, GetAllManagers, GetBranches, UpdateBranch } from "./api";
import useUserPermissions from "hooks/useUserPermissions";
import EmptyContentMessage from "components/EmptyContentMessage/EmptyContentMessage";
function BranchManagement() {
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const [page, setPage] = useState(1);
  const [openFormModal, setOpenFormModal] = useState(false);
  const [confirm, setConfirm] = useState({ open: false, id: "" });
  const [branchData, setBranchData] = useState({});

  const { mode } = useSelector((state) => state.theme);
  const themMode = useThemeMode(mode);
  const all_permissions = useUserPermissions();

  // Get all branches
  const { data: branches, isLoading } = useQuery({
    queryKey: ["branches", { page }],
    queryFn: GetBranches,
  });

  function handleClose() {
    setConfirm({ open: false, id: "" });
  }

  const [modal, setModal] = useState({
    data: {
      name: "",
      user_phone: "",
      position: "",
      type: "parent",
      email: "",
    },
    open: false,
    update: false,
  });

  function handleCheck(branch) {
    if (branchData.id === branch.id) {
      setBranchData({});
    } else {
      setBranchData(branch);
    }
  }

  function openEdit() {
    if (!branchData.id) return notifyError("You must select parent first");
    setOpenFormModal(true);
  }
  function openDelete() {
    if (!branchData.id) return notifyError("You must select parent first");
    setConfirm({ open: true });
  }

  return (
    <>
      <Box sx={{ minHeight: "calc( 100vh - 123px) " }}>
        {/* Page content */}
        <Container fluid>
          <BreadCrumbs pageName={t("user.branches_management")} />
          {/* Table */}
          <Row>
            <div className="col">
              <Card
                style={{
                  backgroundColor: themMode === "dark" ? "#1F2A37" : "",
                }}
              >
                <CardHeader className={`border-0 bg-${themMode !== "dark" || "transparent"}`}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <Box>
                      <h3 className={`mb-0 text-${themMode === "light" ? "black" : "white"}`}>
                        {t("user.branches_management")}
                      </h3>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        gap: "10px",
                        paddingBottom: "10px",
                      }}
                    >
                      {all_permissions?.includes("Add branch") && (
                        <Button
                          onClick={() => setOpenFormModal(true)}
                          className={`${themMode === "dark" ? "dark-button-man" : ""}`}
                          sx={{
                            color: "black",
                            backgroundColor: "#fff",
                            fontSize: "15px",
                            transition: ".4s",
                            display: "flex",
                            gap: "5px",
                            boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",

                            padding: "8px 10px",
                            borderRadius: "10px",
                            textTransform: "none",
                            "&:hover": {
                              backgroundColor: "#fff",
                              opacity: ".8",
                            },
                          }}
                        >
                          <AddCircleIcon sx={{ color: "#71DCD4" }} /> Add
                        </Button>
                      )}
                      {all_permissions?.includes("Update branch") && (
                        <Button
                          onClick={openEdit}
                          className={`${themMode === "dark" ? "dark-button-man" : ""}`}
                          sx={{
                            color: "black",
                            backgroundColor: "#fff",
                            display: "flex",
                            gap: "5px",
                            fontSize: "15px",
                            transition: ".4s",
                            boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
                            padding: "8px 10px",
                            borderRadius: "10px",
                            textTransform: "none",
                            "&:hover": {
                              backgroundColor: "#fff",
                              opacity: ".8",
                            },
                          }}
                        >
                          <EditIcon /> Edit
                        </Button>
                      )}
                      {all_permissions?.includes("Delete branch") && (
                        <Button
                          sx={{
                            color: "black",
                            backgroundColor: "#fff",
                            fontSize: "15px",
                            transition: ".4s",
                            boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
                            padding: "8px 10px",
                            borderRadius: "10px",
                            display: "flex",
                            gap: "5px",
                            textTransform: "none",
                            "&:hover": {
                              backgroundColor: "#fff",
                              opacity: ".8",
                            },
                          }}
                          className={`${themMode === "dark" ? "dark-button-man" : ""}`}
                          onClick={openDelete}
                        >
                          <DeleteIcon sx={{ color: "#C81E1E" }} /> Delete
                        </Button>
                      )}
                    </Box>
                  </Box>
                </CardHeader>
                {isLoading ? (
                  <Box sx={{ display: "flex", justifyContent: "center" }}>
                    <CircularProgress />
                  </Box>
                ) : branches?.data?.data?.length > 0 ? (
                  <Table className={`align-items-center  table-${mode} table-flush`} responsive>
                    <thead
                      style={{
                        backgroundColor: themMode === "dark" ? "#374151" : "#F9FAFB",
                        color: "#6B7280",
                      }}
                    >
                      <tr>
                        <th
                          style={{
                            textAlign: "center",
                            padding: "20px 0px",
                            fontSize: "12px",
                            width: "8px",
                          }}
                        ></th>
                        <th
                          style={{
                            textAlign: "center",
                            padding: "20px ",
                            fontSize: "12px",
                          }}
                          scope="col"
                        >
                          {t("branch.name")}
                        </th>
                        <th
                          style={{
                            textAlign: "center",
                            padding: "20px ",
                            fontSize: "12px",
                          }}
                          scope="col"
                        >
                          {t("branch.created_date")}
                        </th>
                        <th
                          style={{
                            textAlign: "center",
                            padding: "20px ",
                            fontSize: "12px",
                          }}
                          scope="col"
                        >
                          {t("branch.updated_date")}
                        </th>
                      </tr>
                    </thead>
                    {all_permissions?.includes("Branches") && (
                      <tbody
                        style={{
                          backgroundColor: themMode === "dark" ? "#1F2A37" : "white",
                        }}
                      >
                        {branches.data?.data?.map((branch, index) => (
                          <tr key={index}>
                            <td style={{ textAlign: "center" }}>
                              <Checkbox
                                onClick={() => handleCheck(branch)}
                                checked={branchData?.id === branch.id}
                              />
                            </td>
                            <th style={{ textAlign: "center" }} scope="row">
                              {branch?.name || "-"}
                            </th>
                            <td style={{ textAlign: "center" }}>{branch?.created_at || "-"}</td>
                            <td style={{ textAlign: "center" }}>{branch?.updated_at || "-"}</td>
                          </tr>
                        ))}
                      </tbody>
                    )}
                  </Table>
                ) : (
                  <EmptyContentMessage
                    onClick={() => {
                      if (all_permissions?.includes("Add branch")) {
                        setOpenFormModal(true);
                      } else {
                        notifyError("You don't have permission to add.");
                      }
                    }}
                    buttonText={"Add"}
                  />
                )}

                {/* Pagination */}
                <Stack
                  direction="row"
                  sx={{
                    margin: "10px 0",
                    justifyContent: "center",
                    padding: "10px",
                    display: branches?.data?.data?.length > 0 ? "flex" : "none",
                  }}
                  gap={2}
                  justifyContent={"center"}
                >
                  <Pagination
                    count={branches?.data?.last_page || 1}
                    variant="outlined"
                    shape="rounded"
                    value={page}
                    onChange={(e, value) => setPage(value)}
                  />
                </Stack>
              </Card>
            </div>
          </Row>
        </Container>
      </Box>
      <CloseModel
        open={confirm.open}
        handleClose={handleClose}
        fun={DeleteBranch}
        id={branchData.id}
        page={page}
        mutateFun={() => {
          queryClient.invalidateQueries({
            queryKey: ["branches"],
          });
        }}
        msg={t("user.deleteParent")}
      />

      <AddModel
        input={modal.data}
        update={modal.update}
        page={page}
        id={modal?.data?.id}
        data={modal.data}
        open={modal.open}
        handleClose={handleClose}
      />

      {/* Create || Edit branch modal */}
      <GlobalModal
        modalHeaderTypography={t("branch.create_branch")}
        open={openFormModal}
        handleClose={() => {
          setOpenFormModal(false);
        }}
      >
        <BranchForm
          handleClose={() => {
            setOpenFormModal(false);
          }}
          branchId={branchData?.id || null}
          initialManagerId={branchData?.manager_id || ""}
          branchName={branchData?.name || ""}
          setBranchData={setBranchData}
        />
      </GlobalModal>
    </>
  );
}

export default BranchManagement;

function BranchForm({
  handleClose,
  branchId = null,
  initialManagerId = "",
  branchName = "",
  setBranchData = null,
}) {
  const [managerId, setManagerId] = useState(initialManagerId);
  const [branchNameState, setBranchNameState] = useState(branchName);
  const { t } = useTranslation();

  // Get all managers
  const { data: managersData, isLoading } = useQuery({
    queryKey: ["managers"],
    queryFn: GetAllManagers,
  });

  const queryClient = useQueryClient();
  const { mutate } = useMutation({
    mutationFn: branchId ? UpdateBranch : AddBranch,
    onSuccess: (data) => {
      queryClient.invalidateQueries({
        queryKey: ["branches"],
      });
      notifySuccess(
        branchId ? "Branch updated successfully" : "Branch added successfully"
        // : "تم اضافة فرع جديد"
      );
      if (branchId) {
        setBranchData((prev) => {
          return { ...prev, name: branchNameState, manager_id: managerId };
        });
      }
      handleClose();
    },
    onError: (err) => {
      if (err.response) {
        const status = err.response.status;
        if (status === 422) {
          const errors = err.response.data.errors;
          const errorMessages = [];

          if (errors.name) {
            errorMessages.push(`Name: ${errors.name.join(", ")}`);
          }
          if (errors.manager_id) {
            errorMessages.push(`Manager: ${errors.manager_id.join(", ")}`);
          }

          errorMessages.forEach((message) => {
            notifyError(message);
          });
        } else {
          notifyError(err.message);
          err?.response?.data?.message && notifyError(err?.response?.data?.message);
        }
      } else {
        // console.log("Error", err.message);
      }
    },
  });

  const handleSelectChange = (event) => {
    setManagerId(event.target.value);
  };

  const onSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    const name = formData.get("name");
    const managerID = formData.get("manager_id");

    if (name.trim() === "" || managerID.trim() === "") {
      notifyError("Branch name and manager are required!");
    }

    if (branchId) {
      mutate({ formData, id: branchId });
    } else {
      mutate(formData);
    }

    // Call API to create branch
  };

  useEffect(() => {
    if (branchId && managerId) {
      setManagerId(managerId);
    }
  }, [branchId, managerId]);

  return (
    <form onSubmit={onSubmit} className="py-4">
      <TextField
        label={t("branch.branch_name")}
        variant="outlined"
        size="small"
        required
        fullWidth
        className="mb-4"
        name="name"
        type="text"
        defaultValue={branchNameState}
        onChange={(event) => {
          setBranchNameState(event.target.value);
        }}
      />

      <FormControl fullWidth className="mb-4">
        <InputLabel id="Manager">Manager</InputLabel>
        <Select
          labelId="Manager"
          id="selectManager"
          value={managerId}
          name="manager_id"
          label="Manager"
          onChange={handleSelectChange}
          required
          disabled={isLoading}
        >
          {managersData?.data?.map((manager) => (
            <MenuItem value={manager.id} key={manager.id}>
              {manager.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <Button type="submit" variant="contained" color="primary" fullWidth>
        {branchId ? "Update" : t("branch.create")}
      </Button>
    </form>
  );
}
