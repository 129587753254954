import React from "react";
import SmileFace from "../../assets/img/global/Smile_face.png";
import { useTranslation } from "react-i18next";
import { Button } from "@mui/material";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { useSelector } from "react-redux";

function EmptyContentMessage({ onClick = () => {}, buttonText = "" }) {
  const { mode } = useSelector((state) => state.theme);
  const { t } = useTranslation();
  return (
    <div
      style={{ height: "60svh" }}
      className="d-flex align-items-center flex-column justify-content-center"
    >
      <img src={SmileFace} alt="There is no data" className="mb-5" />

      <p>{t("emptyContentMessage.records_empty")}</p>
      <p className="mb-4">{t("emptyContentMessage.no_data_added_yet")}</p>

      <Button
        sx={{
          color: "black",
          backgroundColor: "#fff",
          fontSize: "15px",
          transition: ".4s",
          display: buttonText ? "flex" : "none",
          gap: "5px",
          boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",

          padding: "8px 10px",
          borderRadius: "10px",
          textTransform: "none",
          "&:hover": {
            backgroundColor: "#fff",
            opacity: ".8",
          },
        }}
        className={`${mode == "dark" ? "dark-button-man" : ""}, ${
          buttonText == false ? "d-none" : "d-block"
        }`}
        variant="outlined"
        onClick={onClick}
      >
        <AddCircleIcon sx={{ color: "#71DCD4" }} />
        {buttonText}
      </Button>
    </div>
  );
}

export default EmptyContentMessage;
