import Api from "config/api";
import { useTranslation } from "react-i18next";
import { notifyError } from "utilites/toastify";

export const GetStudent = async (page) => {
  try {
    const res = await Api.get("api/users/student", {
      params: {
        per_page: 10,
        page: page,
      },
    });
    return res.data;
  } catch (error) {
    // console.error("Error fetching student data:", error);
    return error;
  }
};

export const AddStudent = async (data) => {
  const config = {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  };

  const res = await Api.post("api/users/new/user", data, config);
  return res.data;

};

export const UpdateStudent = async (data) => {
  const config = {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  };
  const res = await Api.post(`api/users/user/update/${data.id}`, data.formData, config);
  return res.data;

};

export const DeleteStudent = async ({ id }) => {
  const res = await Api.delete(`api/users/delete/user/${id}`);
  return res.data;

};
