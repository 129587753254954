import { configureStore } from "@reduxjs/toolkit";
import { persistReducer } from "redux-persist";
import modeSlice from "./slices/mode.slice";
import storage from "redux-persist/lib/storage";
import { thunk } from "redux-thunk";
import userSlice from "./slices/user.slice";

const persistConfig = {
  key: "root",
  storage,
};

const persistedModeSlice = persistReducer(persistConfig, modeSlice);
const persistedUserSlice = persistReducer(persistConfig, userSlice);

export const store = configureStore({
  reducer: {
    theme: persistedModeSlice,
    user: persistedUserSlice,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck: false,
  }).concat(thunk),
}); 