import axios from "axios";
import { notifyError } from "../utilites/toastify";
import { store } from "../redux/store"
import { logout } from "../redux/slices/user.slice";
import i18next from 'i18next';

const Api = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

Api.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("access_token");
    const currentLang = i18next.language;

    if (token) {
      config.headers.Authorization = token;
    }
    config.headers['lang'] = currentLang;

    return config;
  },
  (error) => Promise.reject(error)
);

Api.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response?.status === 401) {
      store.dispatch(logout());
    }
    return Promise.reject(error);
  }
);

export const handleApiError = (error) => {
  try {
    if (Array.isArray(error?.response?.data)) {
      error.response.data.map((e) => notifyError(e.message));
    } else {
      const errorMes =
        error.response?.data?.error || error.response?.data?.message;
      // console.log(errorMes);
      notifyError(errorMes);
    }
    return error.response.data.error;
  } catch (error) {
    // console.log(error);
  }
};

export default Api;
