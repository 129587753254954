import { Avatar, Box, Button, Checkbox, CircularProgress, Pagination, Stack } from "@mui/material";
import React, { useState } from "react";
import { Card, CardHeader, Table, Container, Row } from "reactstrap";
import DeleteIcon from "@mui/icons-material/Delete";
// core components
import CloseModel from "../../components/Modal/ConfirmationDialogSelect";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useQuery } from "@tanstack/react-query";
import { DeleteStudent, GetStudent } from "./api";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import EditIcon from "@mui/icons-material/Edit";
import { notifyError } from "utilites/toastify";
import AddModel from "components/Modal/AddModal";
import BreadCrumbs from "components/BreadCrumbs";
import useUserPermissions from "hooks/useUserPermissions";
import EmptyContentMessage from "components/EmptyContentMessage/EmptyContentMessage";

export default function StudentManagment() {
  const { mode } = useSelector((state) => state.theme);
  const [page, setPage] = useState(1);
  const [confirm, setConfirm] = useState({ open: false, id: "" });
  const all_permissions = useUserPermissions();
  const { t, i18n } = useTranslation();
  const { data, isLoading } = useQuery({
    queryKey: ["student", { page }],
    queryFn: () => GetStudent(page),
  });

  function handleClose() {
    setConfirm({ open: false, id: "" });
    setModal({
      open: false,
      id: "",
      data: {
        name: "",
        user_phone: "",
        position: "",
        department: "",
        // equipment: "",
        type: "student",
      },
    });
  }

  const [studentData, setStudentData] = useState({});
  const [modal, setModal] = useState({
    data: {
      name: "",
      user_phone: "",
      position: "",
      department: "",
      // equipment: "",
      type: "student",
    },
    open: false,
    update: false,
  });
  function handleCheck(student) {
    if (studentData.id == student.id) {
      setStudentData({});
    } else {
      setStudentData(student);
    }
  }

  function openEdit() {
    if (!studentData.id) return notifyError("You must select student first");
    setModal({ update: true, open: true, data: studentData });
  }
  function openDelete() {
    if (!studentData.id) return notifyError("You must select student first");
    setConfirm({ open: true });
  }
  return (
    <>
      <Box sx={{ minHeight: "calc( 100vh - 123px) " }}>
        <Container className="" fluid>
          <BreadCrumbs pageName={t("managment.man")} />
          <Row>
            <div className="col">
              <Card style={{ backgroundColor: mode == "dark" ? "#1F2A37" : "" }}>
                <CardHeader className={`border-0 bg-${mode != "dark" || "transparent"}`}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <Box>
                      <h3 className={`mb-0 text-${mode == "light" ? "black" : "white"}`}>
                        {t("managment.man")}
                      </h3>{" "}
                      {/* <Typography
                        sx={{
                          color: "gray",
                          marginBottom: "16px",
                          marginTop: "8px",
                        }}
                        variant="subtitle2"
                      >
                        create,read,update,or delete equipmant , department ,
                        and position
                      </Typography> */}
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        gap: "10px",
                        paddingBottom: "10px",
                      }}
                    >
                      {all_permissions.includes("Add student") && (
                        <Button
                          onClick={() => setModal({ open: true, update: false, data: {} })}
                          className={`${mode == "dark" ? "dark-button-man" : ""}`}
                          sx={{
                            color: "black",
                            backgroundColor: "#fff",
                            fontSize: "15px",
                            transition: ".4s",
                            display: "flex",
                            gap: "5px",
                            boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",

                            padding: "8px 10px",
                            borderRadius: "10px",
                            textTransform: "none",
                            "&:hover": {
                              backgroundColor: "#fff",
                              opacity: ".8",
                            },
                          }}
                        >
                          <AddCircleIcon sx={{ color: "#71DCD4" }} /> Add
                        </Button>
                      )}
                      {all_permissions.includes("Update student") && (
                        <Button
                          onClick={openEdit}
                          className={`${mode == "dark" ? "dark-button-man" : ""}`}
                          sx={{
                            color: "black",
                            backgroundColor: "#fff",
                            display: "flex",
                            gap: "5px",
                            fontSize: "15px",
                            transition: ".4s",
                            boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
                            padding: "8px 10px",
                            borderRadius: "10px",
                            textTransform: "none",
                            "&:hover": {
                              backgroundColor: "#fff",
                              opacity: ".8",
                            },
                          }}
                        >
                          <EditIcon /> Edit
                        </Button>
                      )}
                      {all_permissions.includes("Delete student") && (
                        <Button
                          sx={{
                            color: "black",
                            backgroundColor: "#fff",
                            fontSize: "15px",
                            transition: ".4s",
                            boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
                            padding: "8px 10px",
                            borderRadius: "10px",
                            display: "flex",
                            gap: "5px",
                            textTransform: "none",
                            "&:hover": {
                              backgroundColor: "#fff",
                              opacity: ".8",
                            },
                          }}
                          className={`${mode == "dark" ? "dark-button-man" : ""}`}
                          onClick={openDelete}
                        >
                          <DeleteIcon sx={{ color: "#C81E1E" }} /> Delete
                        </Button>
                      )}
                    </Box>
                  </Box>
                </CardHeader>
                {isLoading ? (
                  <Box sx={{ display: "flex", justifyContent: "center" }}>
                    <CircularProgress />
                  </Box>
                ) : data?.data?.length > 0 ? (
                  <>
                    {all_permissions.includes("Student profiles") && (
                      <Table className={`align-items-center table-${mode} table-flush`} responsive>
                        <thead
                          style={{
                            backgroundColor: mode == "dark" ? "#374151" : "#F9FAFB",
                            color: "#6B7280",
                          }}
                        >
                          <tr>
                            <th
                              style={{
                                textAlign: "center",
                                // padding: "20px 0px",
                                // fontSize: "12px",
                                // width: "8px",
                              }}
                            >
                              {/* <Checkbox />{" "} */}
                            </th>
                            <th
                              scope="col"
                              style={{
                                textAlign: "center",
                                padding: "20px ",
                                fontSize: "12px",
                              }}
                            >
                              {t("managment.name")}
                            </th>
                            {/* <th style={{
                            textAlign: "center",
                            padding: "20px ",
                            fontSize: "12px",
                          }} scope="col">
                              {t("managment.email")}
                            </th> */}
                            <th
                              style={{
                                textAlign: "center",
                                padding: "20px ",
                                fontSize: "12px",
                              }}
                              scope="col"
                            >
                              {t("managment.num")}
                            </th>
                            <th
                              style={{
                                textAlign: "center",
                                padding: "20px ",
                                fontSize: "12px",
                              }}
                              scope="col"
                            >
                              {t("managment.phone")}
                            </th>
                            <th
                              style={{
                                textAlign: "center",
                                padding: "20px ",
                                fontSize: "12px",
                              }}
                              scope="col"
                            >
                              {t("managment.dep")}
                            </th>
                            {/* <th style={{
                            textAlign: "center",
                            padding: "20px ",
                            fontSize: "12px",
                          }} scope="col">
                              {t("managment.pos")}
                            </th> */}
                            <th
                              style={{
                                textAlign: "center",
                                padding: "20px ",
                                fontSize: "12px",
                              }}
                              scope="col"
                            >
                              {t("managment.stCard")}
                            </th>
                            {/*<th*/}
                            {/*  style={{*/}
                            {/*    textAlign: "center",*/}
                            {/*    padding: "20px ",*/}
                            {/*    fontSize: "12px",*/}
                            {/*  }}*/}
                            {/*  scope="col"*/}
                            {/*>*/}
                            {/*  {t("managment.eq")}*/}
                            {/*</th>*/}
                            <th
                              style={{
                                textAlign: "center",
                                padding: "20px ",
                                fontSize: "12px",
                              }}
                              scope="col"
                            >
                              {t("managment.id")}
                            </th>
                            <th
                              style={{
                                textAlign: "center",
                                padding: "20px ",
                                fontSize: "12px",
                              }}
                              scope="col"
                            >
                              {t("managment.balance")}
                            </th>

                            {/* {permissions.includes("Update student") && (
                              <th style={{ textAlign: "center" }} scope="col">
                                {t("edit")}
                              </th>
                            )}
                            {permissions.includes("Delete student") && (
                              <th style={{ textAlign: "center" }} scope="col">
                                {t("del")}
                              </th>
                            )} */}
                          </tr>
                        </thead>
                        <tbody style={{ backgroundColor: mode == "dark" ? "#1F2A37" : "white" }}>
                          {data?.data?.map((student, index) => (
                            <tr key={index}>
                              <td style={{ textAlign: "center" }}>
                                <Checkbox
                                  onChange={() => handleCheck(student)}
                                  checked={studentData?.id === student.id}
                                />
                              </td>
                              <td
                                style={{
                                  textAlign: "center",
                                  display: "flex",
                                  gap: "5px",
                                  alignItems: "center",
                                }}
                              >
                                <Avatar alt="Remy Sharp" src={student?.profile_img} />
                                {student?.name}
                              </td>
                              {/* <td style={{ textAlign: "center" }}>
                                {student?.email}
                              </td> */}
                              <td style={{ textAlign: "center" }}>{student?.user_number}</td>
                              <td style={{ textAlign: "center" }}>{student?.user_phone}</td>
                              <td style={{ textAlign: "center" }}>{student?.department}</td>
                              {/* <td style={{ textAlign: "center" }}>
                                {student?.position}
                              </td> */}
                              <td style={{ textAlign: "center" }}>{student?.user_card}</td>
                              {/*<td style={{ textAlign: "center" }}>*/}
                              {/*  {student?.equipment}*/}
                              {/*</td>*/}
                              <td style={{ textAlign: "center" }}>{student?.identity_id}</td>
                              <td style={{ textAlign: "center" }}>
                                {i18n.language === "en"
                                  ? `${student?.balance} SAR`
                                  : `${student?.balance} ريال`}
                              </td>
                              {/* {permissions.includes("Update student") && (
                                <td style={{ textAlign: "center" }}>
                                  <AddModel
                                    input={{
                                      name: student?.name,
                                      user_phone: student?.user_phone,
                                      department: student?.department,
                                      // user_number: student?.user_number,
                                      user_card: student?.user_card,
                                      equipment: student?.equipment,
                                      identity_id: student?.identity_id,
                                      status: student?.card_state,

                                      role: "",
                                      type: "student",

                                      profile_img: student?.profile_img,
                                    }}
                                    update={true}
                                    page={page}
                                    id={student?.id}
                                  />
                                </td>
                              )}
                              {permissions.includes("Delete student") && (
                                <td style={{ textAlign: "center" }}>
                                  <IconButton
                                    onClick={() =>
                                      setConfirm({
                                        open: true,
                                        id: student?.id,
                                      })
                                    }
                                    sx={{
                                      backgroundColor: "red",
                                      color: "white",
                                    }}
                                  >
                                    <DeleteIcon />
                                  </IconButton>
                                </td>
                              )} */}
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    )}
                  </>
                ) : (
                  <EmptyContentMessage
                    onClick={() => {
                      if (all_permissions?.includes("Add student")) {
                        setModal({ open: true, update: false, data: {} });
                      } else {
                        notifyError("You don't have permission to add.");
                      }
                    }}
                    buttonText={"Add"}
                  />
                )}
                {/* <CardFooter
                  className={`py-4 bg-${mode != "dark" || "transparent"}`}
                >
                  <nav aria-label="..."> */}

                <Stack
                  direction="row"
                  sx={{
                    margin: "10px 0",
                    justifyContent: "center",
                    padding: "10px",
                    display: data?.data?.length > 0 ? "flex" : "none",
                  }}
                  gap={2}
                  justifyContent={"center"}
                >
                  <Pagination
                    count={data?.last_page || 1}
                    variant="outlined"
                    shape="rounded"
                    value={page}
                    onChange={(e, value) => setPage(value)}
                  />
                </Stack>
                {/* </nav>
                </CardFooter> */}
              </Card>
            </div>
          </Row>
        </Container>
      </Box>
      <CloseModel
        open={confirm.open}
        handleClose={handleClose}
        fun={DeleteStudent}
        id={studentData.id}
        page={page}
        keys="student"
        name="Student"
      />
      {modal.open && (
        <AddModel
          input={modal.data}
          update={modal.update}
          page={page}
          id={modal?.data?.id}
          data={modal.data}
          open={modal.open}
          handleClose={handleClose}
        />
      )}
    </>
  );
}
