import { Box, Tabs, Typography } from "@mui/material";
import React, { useEffect } from "react";
import Tab from "@mui/material/Tab";
import "../../css/index.css";
import Recharge from "pages/Recharge/Recharge";
import DeductionDevice from "pages/DeductionDevice/DeductionDevice";
import BreadCrumbs from "components/BreadCrumbs";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const EquipmentManegment = () => {
  const [value, setValue] = React.useState("1");
  const {  mode } = useSelector((state) => state.theme);
  const { i18n } = useTranslation()
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  useEffect(() => {
    setValue(0);
  }, []);
  // console.log(i18n.language);
  return (
    <Box minHeight="89.5vh" sx={{ padding: "36px" }}>
      <BreadCrumbs pageName={value == 1 ? (i18n.language == "en" ? "Deduction" : "عمليات الخصم") : (i18n.language == "en" ? "Recharge" : "عمليات الشحن")} />
      <Box
        sx={{
          borderRadius: "16px",
          width: "100%",
          typography: "body1",
          backgroundColor: mode == "dark" ? "#1F2A37" : "#fff",
          padding: "24px",
          direction: i18n.language == "en" ? "ltr" : "rtl"
        }}
      >
        <Typography
          fontWeight="bold"
          variant="h6"
          sx={{ color: mode == "light" ? "black" : "white", textAlign: i18n.language == "en" ? "start" : "end" }}
        >
          {i18n.language == "en" ? "Equipment Manegment" : "ادارة المعدات"}
        </Typography>
        <Typography
          sx={{ color: "gray", marginBottom: "16px", marginTop: "8px", textAlign: i18n.language == "en" ? "start" : "end" }}
          variant="subtitle2"
        >
          {i18n.language == "en" ? "Recharge or deduct balance amounts from cards" : "إعادة شحن أو خصم مبالغ الرصيد من البطاقات"}
        </Typography>
        <Box sx={{ width: "100%" }}>
          <Tabs
            value={value == 1 ? 1 : 0}
            onChange={handleChange}
            variant="fullWidth"
            // indicatorColor="primary"
            textColor="primary"
            aria-label="full width tabs example"
          >
            <Tab
              label={i18n.language == "en" ? "Recharge" : "عمليات الشحن"}
              style={{ color: value === 0 ? (mode == "dark" ? "#fff" : "#111928") : "#6B7280", }}
              sx={{ fontWeight: "bold", backgroundColor: mode == "dark" ? "#374151 !important" : "#fff !important" }}
            />
            <Tab
              label={i18n.language == "en" ? "Deduction" : "عميات الخصم"}
              style={{ color: value === 1 ? (mode == "dark" ? "#fff" : "#111928") : "#6B7280", }}
              sx={{ fontWeight: "bold", backgroundColor: mode == "dark" ? "#374151 !important" : "#fff !important" }}
            />
          </Tabs>
          <TabPanel value={value} name={"Recharge"} index={0}>
            <Recharge />
          </TabPanel>
          <TabPanel value={value} name="Deduction" index={1}>
            <DeductionDevice />
          </TabPanel>
        </Box>
      </Box>
    </Box>
  );
};
function TabPanel(props) {
  const { name, children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>

          {children}
        </Box>
      )}
    </div>
  );
}
export default EquipmentManegment;
