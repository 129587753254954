import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Modal,
  NativeSelect,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import CloseIcon from "@mui/icons-material/Close";
import { useEffect, useState } from "react";
import { notifyError, notifySuccess } from "../../utilites/toastify";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useTheme } from "@emotion/react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AddStudent, UpdateStudent } from "../../pages/UserManagment/api";
import { getRoles, getUsers } from "../../redux/slices/user.slice";
import { Visibility, VisibilityOff } from '@mui/icons-material';

const AddModel = ({ input, update, page, id, data, open, handleClose }) => {
  const { users, count, permissions } = useSelector((state) => state.user)
  const { direction, mode } = useSelector((state) => state.theme);
  const dispatch = useDispatch()
  const { all_roles } = useSelector((state) => state.user)
  const [studentInput, setStudentInput] = useState(input);
  useEffect(() => {
    setStudentInput(input)
  }, [input]);

  useEffect(() => {
          dispatch(getRoles(page));
  }, [])
  

  const queryClient = useQueryClient();
  const { mutate: mutateAdd } = useMutation({
    mutationFn: AddStudent,
    onSuccess: (data) => {
      queryClient.invalidateQueries({
        queryKey: ["User"],
      });
      notifySuccess(i18n.language == "en" ? "User Added" : "تم اضافة المستخدم");
      handleClose()
      dispatch(getUsers(page))
      dispatch(getRoles(page));
    },
    onError: (err) => {
      if (err.response) {
        // console.log(err.response);
        const status = err.response.status;
        const errorCode = err.code || "ERR_UNKNOWN";
        const errorMessage = err.message || "An unknown error occurred";

        // console.log(`Error Code: ${errorCode}`);
        // console.log(`Error Message: ${errorMessage}`);
        // console.log(`Status: ${status}`);

        if (status === 422) {
          const errors = err.response.data.errors;
          // console.log(errors);
          const errorMessages = [];

          if (errors.email) {
            errorMessages.push(`Email: ${errors.email.join(', ')}`);
          }
          if (errors.user_phone) {
            errorMessages.push(`User Phone: ${errors.user_phone.join(', ')}`);
          }

          errorMessages.forEach(message => {
            notifyError(message)
          });
        }
      } else {
        // console.log("Error", err.message);
      }
    }
  });
  const { mutate: mutateUpdate } = useMutation({
    mutationFn: UpdateStudent,
    onSuccess: (data) => {

      // queryClient.invalidateQueries({
      //   queryKey: ["student"],
      // });
      notifySuccess(i18n.language == "en" ? "User Updated" : "تم تعديل المستخدم");
      handleClose()
      dispatch(getUsers(page))
    },
    onError: (err) => {
      if (err.response) {
        const status = err.response.status;
        const errorCode = err.code || "ERR_UNKNOWN";
        const errorMessage = err.message || "An unknown error occurred";

        if (status === 422) {
          const errors = err.response.data.errors;
          const errorMessages = [];

          if (errors.email) {
            errorMessages.push(`Email: ${errors.email.join(', ')}`);
          }
          if (errors.user_phone) {
            errorMessages.push(`User Phone: ${errors.user_phone.join(', ')}`);
          }
          if (errors.role) {
            errorMessages.push(`Role: ${errors.role.join(', ')}`);
          }
          if (errors.psasword) {
            errorMessages.push(`Password: ${errors.psasword.join(', ')}`);
          }

          errorMessages.forEach(message => {
            notifyError(message)
          });
        }
      } else {
        // console.log("Error", err.message);
      }
    }
  });
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setStudentInput((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault()
    if (studentInput.password && (studentInput.password !== studentInput.confirmPass)) { return notifyError(t("user.passErr")) }
    if (studentInput.password && studentInput.password?.length < 6) {
      return notifyError(i18n.language == "en" ? "Password Must be more than 6 characters" : "كلمة السر يجب ان تكون اكثر من 6 احرف")
    }

    if (update) {
      // console.log(studentInput);
      let dublicatedPhone = users.find((ele) => ele.user_phone == studentInput.user_phone && ele.id != studentInput.id)
      let dublicatedemail = users.find((ele) => ele.email == studentInput.email && ele.id != studentInput.id)

      if (dublicatedPhone) return notifyError(i18n.language == "en" ? "Phone number is already taken" : "رقم الجوال مستخدم بالفعل")
      if (dublicatedemail) return notifyError(i18n.language == "en" ? "Email address is already taken" : "البريد الالكتروني مستخدم بالفعل")


      mutateUpdate({ ...studentInput, id })
    } else {
      let dublicatedPhone = users.find((ele) => ele.user_phone == studentInput.user_phone)
      let dublicatedemail = users.find((ele) => ele.email == studentInput.email)

      if (dublicatedPhone) return notifyError(i18n.language == "en" ? "Phone number is already taken" : "رقم الجوال مستخدم بالفعل")
      if (dublicatedemail) return notifyError(i18n.language == "en" ? "Email address is already taken" : "البريد الالكتروني مستخدم بالفعل")


      mutateAdd({ ...studentInput, type: "system_user" });
    }

  }
  const { t, i18n } = useTranslation();
  const theme = useTheme();
  // const functionopenpopup = () => {
  //   openchange(true);
  // };
  // const closepopup = () => {
  //   openchange(false);
  // };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };
  return (
    <>
      <div style={{ textAlign: "right" }}>
        <Dialog
          // fullScreen
          open={open}
          onClose={handleClose}
          fullWidth
          maxWidth="md"
          sx={{
            //You can copy the code below in your theme

            "& .MuiPaper-root": {
              background: `${mode == "dark" ? "#172B4D" : "white"}`,
            },
            "& .MuiBackdrop-root": {},
          }}
          dir={direction}
        >
          <DialogTitle
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography sx={{ color: theme.palette.text.main }}>
              {update ? t("user.updateUser") : t("managment.addUser")}
            </Typography>{" "}
            <IconButton onClick={handleClose} style={{ float: "right" }}>
              <CloseIcon color="primary"></CloseIcon>
            </IconButton>{" "}
          </DialogTitle>
          <DialogContent>
            <form onSubmit={handleSubmit}>
              <Grid sx={{ padding: "10px" }} container spacing={2}>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    label={t("managment.name")}
                    name="name"
                    required
                    value={studentInput?.name ?? ""}
                    onChange={handleInputChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    label={t("managment.email")}
                    name="email"
                    required
                    autoComplete="email"
                    value={studentInput?.email ?? ""}
                    onChange={handleInputChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    label={t("managment.phone")}
                    name="user_phone"
                    value={studentInput?.user_phone ?? ""}
                    onChange={handleInputChange}
                    inputProps={{ type: "number" }}
                  />
                </Grid>
                {/* <Grid item xs={12} sm={6} md={4} lg={3}>
                <TextField
                  fullWidth
                  variant="outlined"
                  label={t("managment.pos")}
                  name="position"
                  value={studentInput?.position}
                  onChange={handleInputChange}
                />
              </Grid> */}
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <FormControl fullWidth>
                    <InputLabel id="roles-select-label">
                      {t("managment.role")}
                    </InputLabel>
                    <Select
                      labelId="roles-select-label"
                      id="roles-select"
                      value={studentInput?.role ?? "none"}
                      name="role"
                      label="role"
                      required
                      onChange={handleInputChange}
                    >
                      <MenuItem value="none">
                        {t("roles.SelectRole")}
                        </MenuItem>
                      {all_roles?.map((ele,index) => (
                        <MenuItem value={ele.name} key={index}>
                          {ele.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    label={t("user.password")}
                    name="password"
                    value={studentInput?.password ?? ""}
                    onChange={handleInputChange}
                    type={showPassword ? "text" : "password"}
                    required={!update}
                    autoComplete="new-password"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={togglePasswordVisibility}
                            edge="end"
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    label="Confirm Password"
                    name="confirmPass"
                    value={studentInput?.confirmPass ?? ""}
                    onChange={handleInputChange}
                    type={showConfirmPassword ? "text" : "password"}
                    required={!update}
                    autoComplete="new-password"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle confirm password visibility"
                            onClick={toggleConfirmPasswordVisibility}
                            edge="end"
                          >
                            {showConfirmPassword ? (
                              <VisibilityOff />
                            ) : (
                              <Visibility />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Button
                    fullWidth
                    sx={{
                      color: "#fff",
                      backgroundColor: "#1AE584",
                      transition: ".4s",
                      "&:hover": {
                        backgroundColor: "#1AE584",
                        opacity: ".8",
                      },
                    }}
                    variant="contained"
                    type="submit"
                  >
                    {t("Submit")}
                  </Button>
                </Grid>
              </Grid>
            </form>
          </DialogContent>
        </Dialog>
      </div></>
  );
};

export default AddModel;
