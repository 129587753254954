/*eslint-disable*/
import { useEffect, useState } from "react";
import { NavLink as NavLinkRRD, Link, useLocation, useNavigate } from "react-router-dom";
import { PropTypes } from "prop-types";
import {
  Collapse,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  NavbarBrand,
  Navbar,
  Nav,
  Container,
  Row,
  Col,
} from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Collapse as MuiCollapse,
  styled,
  useTheme,
} from "@mui/material";
import { ArrowDropDown, ArrowDropUp, FiberManualRecord } from "@mui/icons-material";
import { loginUser, logout as logoutUser } from "../../redux/slices/user.slice";
import useUserPermissions from "hooks/useUserPermissions";
var ps;
const StyledListItem = styled(ListItem)(({ theme }) => ({
  "&:hover": {
    backgroundColor:
      theme.palette.mode === "dark" ? theme.palette.grey[700] : theme.palette.action.hover,
    color: theme.palette.mode === "dark" ? "#e5e5e5" : "#004E64",
  },
  "&.Mui-selected": {
    backgroundColor: theme.palette.action.selected,
    "&:hover": {
      backgroundColor: theme.palette.action.selected,
      color: theme.palette.mode === "dark" ? "#e5e5e5" : "#004E64",
    },
  },
  "& .MuiListItemIcon-root": {
    minWidth: "40px",
  },
}));

const StyledNestedListItem = styled(ListItem)(({ theme }) => ({
  paddingLeft: theme.spacing(4),
  "& .MuiListItemIcon-root": {
    minWidth: "40px",
  },
  "&:hover": {
    backgroundColor:
      theme.palette.mode === "dark" ? theme.palette.grey[700] : theme.palette.action.hover,
    color: theme.palette.mode === "dark" ? "#e5e5e5" : "#004E64",
  },
  "&.Mui-selected": {
    backgroundColor: theme.palette.action.selected,
    "&:hover": {
      backgroundColor: theme.palette.action.selected,
      color: theme.palette.mode === "dark" ? "#e5e5e5" : "#004E64",
    },
  },
}));

const Sidebar = (props) => {
  const { bgColor, routes: displayedRoutes, logo } = props;

  const [collapseOpen, setCollapseOpen] = useState();
  const { direction, mode } = useSelector((state) => state.theme);
  const { i18n } = useTranslation();
  const user = useSelector((state) => state.user.data);
  // console.log(user);
  const toggleCollapse = () => {
    setCollapseOpen((data) => !data);
  };

  const [openDropdown, setOpenDropdown] = useState(null); // State to manage open dropdowns
  const location = useLocation(); // Get the current route
  const theme = useTheme();

  // Function to toggle the dropdown menu
  const toggleDropdown = (key) => {
    if (openDropdown === key) {
      setOpenDropdown(null); // Close the dropdown if it's already open
    } else {
      setOpenDropdown(key); // Open the clicked dropdown
    }
  };

  const dispatch = useDispatch();
  // Function to close all dropdowns
  const closeAllDropdowns = (name) => {
    setOpenDropdown(null);
    if (name == "Logout") {
      dispatch(logoutUser());
      localStorage.removeItem("access_token");
    }
  };
  const navigate = useNavigate();
  const closeAllDropdowns1 = (e, path) => {
    e.preventDefault();
    setOpenDropdown(null);
    navigate(path);
  };

  const createLinks = (routes) => {
    return routes.map((route, key) => {
      if (route.children) {
        const isOpen = openDropdown === key; // Check if this dropdown is open

        return (
          <List key={key} component="nav">
            <StyledListItem button onClick={() => toggleDropdown(key)}>
              {route.type === "mui" ? (
                route.icon
              ) : (
                <ListItemIcon>
                  <i className={route.icon} style={{ padding: "0 16px " }} />
                </ListItemIcon>
              )}
              <ListItemText primary={i18n.language === "en" ? route.name : route.arName} />
              {isOpen ? <ArrowDropUp /> : <ArrowDropDown />}
            </StyledListItem>
            <MuiCollapse in={isOpen}>
              <List component="div" disablePadding>
                {route.children.map((child, childKey) => {
                  const isSelected = location.pathname === child.layout + child.path; // Check if this route is selected
                  return (
                    <StyledNestedListItem
                      key={childKey}
                      button
                      // component={NavLinkRRD}
                      // to={child.layout + child.path}
                      onClick={(e) => closeAllDropdowns1(e, child.layout + child.path)}
                      selected={isSelected}
                    >
                      {child.type === "mui" ? (
                        child.icon
                      ) : (
                        <ListItemIcon>
                          <i className={child.icon} style={{ padding: "0 16px " }} />
                        </ListItemIcon>
                      )}
                      <ListItemText primary={i18n.language === "en" ? child.name : child.arName} />
                    </StyledNestedListItem>
                  );
                })}
              </List>
            </MuiCollapse>
          </List>
        );
      } else {
        const isSelected = location.pathname === route.layout + route.path; // Check if this route is selected
        return (
          <StyledListItem
            key={key}
            button
            component={NavLinkRRD}
            to={route.layout + route.path}
            onClick={() => closeAllDropdowns(route.name)}
            selected={isSelected}
          >
            {route.type === "mui" ? (
              route.icon
            ) : (
              <ListItemIcon>
                <img src={require("../../assets/img/icons/UI/" + route.icon)} />
              </ListItemIcon>
            )}
            <ListItemText primary={i18n.language === "en" ? route.name : route.arName} />
          </StyledListItem>
        );
      }
    });
  };

  const [routes, setRoutes] = useState([]);

  const allPermissions = useUserPermissions();

  useEffect(() => {
    function getRoutes() {
      const temp = displayedRoutes.filter((ele) => allPermissions.includes(ele.permission_key));
      // console.log("displayedRoutes", displayedRoutes);
      // console.log("allPermissions", allPermissions);
      // console.log("temp", temp);
      setRoutes(temp);
    }

    getRoutes();
  }, [displayedRoutes]);

  let navbarBrandProps;
  if (logo && logo.innerLink) {
    navbarBrandProps = {
      to: logo.innerLink,
      tag: Link,
    };
  } else if (logo && logo.outterLink) {
    navbarBrandProps = {
      href: logo.outterLink,
      target: "_blank",
    };
  }

  // console.log("user: ", user?.user?.role);
  return (
    <Navbar
      data-bs-theme="dark"
      className={`navbar-vertical fixed-${
        direction == "rtl" ? "right" : "left"
      } navbar-${mode} bg-${mode == "light" ? "white" : "sldlsp"}`}
      expand="md"
      id="sidenav-main"
    >
      <Container fluid>
        {/* Toggler */}
        <button className="navbar-toggler" type="button" onClick={toggleCollapse}>
          <span className="navbar-toggler-icon" />
        </button>
        {/* Brand */}
        {logo ? (
          <NavbarBrand className="p-0 text-left" {...navbarBrandProps}>
            <img
              alt={logo.imgAlt}
              className="navbar-brand-img"
              src={require("../../assets/img/brand/Asset.png")}
            />
          </NavbarBrand>
        ) : null}
        {/* User */}
        {/* Collapse */}
        <Collapse navbar isOpen={collapseOpen}>
          {/* Collapse header */}
          <div className="navbar-collapse-header d-md-none">
            <Row>
              {logo ? (
                <Col className="collapse-brand" xs="6">
                  {logo.innerLink ? (
                    <Link to={logo.innerLink}>
                      <img alt={logo.imgAlt} src={require("../../assets/img/brand/Asset.png")} />
                    </Link>
                  ) : (
                    <a href={logo.outterLink}>
                      <img alt={logo.imgAlt} src={require("../../assets/img/brand/Asset.png")} />
                    </a>
                  )}
                </Col>
              ) : null}
              <Col className="collapse-close" xs="6">
                <button className="navbar-toggler" type="button" onClick={toggleCollapse}>
                  <span />
                  <span />
                </button>
              </Col>
            </Row>
          </div>
          {/* Form */}
          <Form className="mt-4 mb-3 d-md-none">
            <InputGroup className="input-group-rounded input-group-merge">
              <Input
                aria-label="Search"
                className="form-control-rounded form-control-prepended"
                placeholder="Search"
                type="search"
              />
              <InputGroupAddon addonType="prepend">
                <InputGroupText>
                  <span className="fa fa-search" />
                </InputGroupText>
              </InputGroupAddon>
            </InputGroup>
          </Form>
          {/* Navigation */}
          {
            <Nav navbar className="p-0">
              {createLinks(routes)}
              <div
                className="p-2 px-4 m-4 rounded-3"
                style={{ backgroundColor: "#EAF1E9", width: "fit-content" }}
              >
                <p style={{ color: "#255F6DB2" }} className="m-0">
                  {" "}
                  {user?.user?.role?.toUpperCase()}
                </p>
              </div>
            </Nav>
          }
        </Collapse>
        <p className="text-center" style={{ color: mode === "dark" ? "#fff" : " #255F6D80" }}>
          Fushaty. 2024
        </p>
      </Container>
    </Navbar>
  );
};

Sidebar.defaultProps = {
  routes: [{}],
};

Sidebar.propTypes = {
  routes: PropTypes.arrayOf(PropTypes.object),
  logo: PropTypes.shape({
    innerLink: PropTypes.string,
    outterLink: PropTypes.string,
    imgSrc: PropTypes.string.isRequired,
    imgAlt: PropTypes.string.isRequired,
  }),
};

export default Sidebar;
