import { Dialog, DialogContent, DialogTitle, IconButton, Typography } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import CloseIcon from "@mui/icons-material/Close";
import useThemeMode from "hooks/useThemeMode";
import { useTheme } from "@emotion/react";
import Divider from "@mui/material/Divider";

function GlobalModal({
  children,
  open,
  handleClose,
  modalHeaderTypography,
  maxWidth = "md",
  ...props
}) {
  const { direction, mode } = useSelector((state) => state.theme);
  const themMode = useThemeMode(mode);

  const theme = useTheme();
  return (
    <Dialog
      // fullScreen
      open={open}
      onClose={handleClose}
      fullWidth
      maxWidth={maxWidth}
      sx={{
        //You can copy the code below in your theme
        "& .MuiPaper-root": {
          background: `${themMode === "dark" ? "#172B4D" : "white"}`,
        },
        "& .MuiBackdrop-root": {},
      }}
      dir={direction}
      {...props}
    >
      <DialogTitle
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography sx={{ color: theme.palette.text.main, fontSize: "22px" }}>
          {modalHeaderTypography}
        </Typography>
        <IconButton onClick={handleClose} style={{ float: "right" }}>
          <CloseIcon color="primary"></CloseIcon>
        </IconButton>
      </DialogTitle>
      <Divider />
      <DialogContent>{children}</DialogContent>
    </Dialog>
  );
}

export default GlobalModal;
