import DeleteIcon from "@mui/icons-material/Delete";
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Pagination,
  Select,
  Stack,
  TextField,
  Tooltip,
} from "@mui/material";
import { Fragment, useEffect, useState } from "react";
import { Card, CardHeader, Container, Row, Table } from "reactstrap";
// core components
import AddCircleIcon from "@mui/icons-material/AddCircle";
import EditIcon from "@mui/icons-material/Edit";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import BreadCrumbs from "components/BreadCrumbs";
import GlobalModal from "components/GlobalModal/GlobalModal";
import CloseModel from "components/Modal/DeleteDialouge";
import useThemeMode from "hooks/useThemeMode";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { notifyError, notifySuccess } from "utilites/toastify";
import { AddMachines, DeleteMachines, GetAllCanteens, GetMachines, UpdateMachines } from "./api";
import useUserPermissions from "hooks/useUserPermissions";
import useTruncateText from "hooks/useTruncateText";
import useFormatDate from "hooks/useFormatDate";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import useClipboard from "hooks/useClipboard";
import Fade from "@mui/material/Fade";
import EmptyContentMessage from "components/EmptyContentMessage/EmptyContentMessage";

function MachinesManagement() {
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const [page, setPage] = useState(1);
  const [openFormModal, setOpenFormModal] = useState(false);
  const [confirm, setConfirm] = useState({ open: false, id: "" });
  const [machineData, setMachineData] = useState({});

  const { mode } = useSelector((state) => state.theme);
  const themMode = useThemeMode(mode);
  const all_permissions = useUserPermissions();
  const formatDate = useFormatDate();
  const truncateText = useTruncateText(20);
  const copyToClipboard = useClipboard();

  // Get all machines
  const { data: machines, isLoading } = useQuery({
    queryKey: ["machines", { page }],
    queryFn: () => GetMachines(page),
  });

  function handleClose() {
    setConfirm({ open: false, id: "" });
  }

  function handleCheck(machine) {
    if (machineData.id === machine.id) {
      setMachineData({});
    } else {
      setMachineData(machine);
    }
  }

  function openEdit() {
    if (!machineData.id) return notifyError("You must select machine first");
    setOpenFormModal(true);
  }

  const handleAddClick = () => {
    if (machineData.id) setMachineData({});
    setOpenFormModal(true);
  };

  function openDelete() {
    if (!machineData.id) return notifyError("You must select machines first");
    setConfirm({ open: true });
  }

  return (
    <>
      <Box sx={{ minHeight: "calc( 100vh - 123px) " }}>
        {/* Page content */}
        <Container fluid>
          <BreadCrumbs pageName={t("machine.title")} />
          {/* Table */}
          <Row>
            <div className="col">
              <Card
                style={{
                  backgroundColor: themMode === "dark" ? "#1F2A37" : "",
                }}
              >
                <CardHeader className={`border-0 bg-${themMode !== "dark" || "transparent"}`}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <Box>
                      <h3 className={`mb-0 text-${themMode === "light" ? "black" : "white"}`}>
                        {t("machine.cashier_device")}
                      </h3>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        gap: "10px",
                        paddingBottom: "10px",
                      }}
                    >
                      {all_permissions?.includes("Add machine") && (
                        <Button
                          onClick={handleAddClick}
                          className={`${themMode === "dark" ? "dark-button-man" : ""}`}
                          sx={{
                            color: "black",
                            backgroundColor: "#fff",
                            fontSize: "15px",
                            transition: ".4s",
                            display: "flex",
                            gap: "5px",
                            boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",

                            padding: "8px 10px",
                            borderRadius: "10px",
                            textTransform: "none",
                            "&:hover": {
                              backgroundColor: "#fff",
                              opacity: ".8",
                            },
                          }}
                        >
                          {t("machine.add")}
                          <AddCircleIcon sx={{ color: "#71DCD4" }} />
                        </Button>
                      )}
                      {all_permissions?.includes("Update machine") && (
                        <Button
                          onClick={openEdit}
                          className={`${themMode === "dark" ? "dark-button-man" : ""}`}
                          sx={{
                            color: "black",
                            backgroundColor: "#fff",
                            display: "flex",
                            gap: "5px",
                            fontSize: "15px",
                            transition: ".4s",
                            boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
                            padding: "8px 10px",
                            borderRadius: "10px",
                            textTransform: "none",
                            "&:hover": {
                              backgroundColor: "#fff",
                              opacity: ".8",
                            },
                          }}
                        >
                          {t("machine.edit")}
                          <EditIcon />
                        </Button>
                      )}
                      {all_permissions?.includes("Delete machine") && (
                        <Button
                          sx={{
                            color: "black",
                            backgroundColor: "#fff",
                            fontSize: "15px",
                            transition: ".4s",
                            boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
                            padding: "8px 10px",
                            borderRadius: "10px",
                            display: "flex",
                            gap: "5px",
                            textTransform: "none",
                            "&:hover": {
                              backgroundColor: "#fff",
                              opacity: ".8",
                            },
                          }}
                          className={`${themMode === "dark" ? "dark-button-man" : ""}`}
                          onClick={openDelete}
                        >
                          {t("machine.delete")}
                          <DeleteIcon sx={{ color: "#C81E1E" }} />
                        </Button>
                      )}
                    </Box>
                  </Box>
                </CardHeader>
                {isLoading ? (
                  <Box sx={{ display: "flex", justifyContent: "center" }}>
                    <CircularProgress />
                  </Box>
                ) : machines?.data?.data?.length > 0 ? (
                  <Table className={`align-items-center  table-${mode} table-flush`} responsive>
                    <thead
                      style={{
                        backgroundColor: themMode === "dark" ? "#374151" : "#F9FAFB",
                        color: "#6B7280",
                      }}
                    >
                      <tr>
                        {/* Check box */}
                        <th
                          style={{
                            textAlign: "center",
                            padding: "20px 0px",
                            fontSize: "12px",
                            width: "8px",
                          }}
                        >
                          -
                        </th>

                        {/* Machine name */}
                        <th
                          style={{
                            textAlign: "start",
                            padding: "20px ",
                            fontSize: "12px",
                          }}
                          scope="col"
                        >
                          {t("machine.name")}
                        </th>

                        {/* Machine code */}
                        <th
                          style={{
                            textAlign: "start",
                            padding: "20px ",
                            fontSize: "12px",
                          }}
                          scope="col"
                        >
                          {t("machine.code")}
                        </th>

                        {/* Created at */}
                        <th
                          style={{
                            textAlign: "center",
                            padding: "20px ",
                            fontSize: "12px",
                          }}
                          scope="col"
                        >
                          {t("machine.created_at")}
                        </th>

                        {/* Updated at */}
                        <th
                          style={{
                            textAlign: "center",
                            padding: "20px ",
                            fontSize: "12px",
                          }}
                          scope="col"
                        >
                          {t("machine.updated_at")}
                        </th>

                        {/* Device Token */}
                        <th
                          style={{
                            textAlign: "center",
                            padding: "20px ",
                            fontSize: "12px",
                          }}
                          scope="col"
                        >
                          {t("machine.device_token")}
                        </th>

                        {/* FCM Token */}
                        <th
                          style={{
                            textAlign: "center",
                            padding: "20px ",
                            fontSize: "12px",
                          }}
                          scope="col"
                        >
                          {t("machine.fcm_token")}
                        </th>
                      </tr>
                    </thead>

                    {all_permissions?.includes("Machines") && (
                      <tbody
                        style={{
                          backgroundColor: themMode === "dark" ? "#1F2A37" : "white",
                        }}
                      >
                        {machines?.data?.data?.map((machine, index) => (
                          <tr key={index}>
                            <td style={{ textAlign: "center" }}>
                              <Checkbox
                                onClick={() => handleCheck(machine)}
                                checked={machineData?.id === machine.id}
                              />
                            </td>

                            {/* Machine name */}
                            <td style={{ textAlign: "start" }} scope="row">
                              {machine?.name || "-"}
                            </td>

                            {/* Machine code */}
                            <td style={{ textAlign: "start" }}>{machine?.code || "-"}</td>

                            {/* Created at */}
                            <td style={{ textAlign: "center" }}>
                              {machine?.created_at ? formatDate(machine?.created_at) : "-"}
                            </td>

                            {/* Updated at */}
                            <td style={{ textAlign: "center" }}>
                              {machine?.updated_at ? formatDate(machine?.updated_at) : "-"}
                            </td>

                            {/* Device Token */}
                            <td style={{ textAlign: "center" }}>
                              {machine?.device_token ? (
                                <Fragment>
                                  <Tooltip
                                    title={machine?.device_token}
                                    TransitionProps={{ timeout: 600 }}
                                    TransitionComponent={Fade}
                                  >
                                    <span>{truncateText(machine?.device_token, 10)}</span>
                                  </Tooltip>

                                  <Tooltip title={t("copy_to_clipboard")}>
                                    <Button onClick={() => copyToClipboard(machine?.device_token)}>
                                      <ContentCopyIcon />
                                    </Button>
                                  </Tooltip>
                                </Fragment>
                              ) : (
                                "-"
                              )}
                            </td>

                            {/* FCM Token */}
                            <td style={{ textAlign: "center" }}>
                              {machine?.FCM_token ? (
                                <Fragment>
                                  <Tooltip
                                    title={machine?.FCM_token}
                                    TransitionProps={{ timeout: 600 }}
                                    TransitionComponent={Fade}
                                  >
                                    <span>{truncateText(machine?.FCM_token, 10)}</span>
                                  </Tooltip>

                                  <Tooltip title={t("copy_to_clipboard")}>
                                    <Button onClick={() => copyToClipboard(machine?.FCM_token)}>
                                      <ContentCopyIcon />
                                    </Button>
                                  </Tooltip>
                                </Fragment>
                              ) : (
                                "-"
                              )}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    )}
                  </Table>
                ) : (
                  <EmptyContentMessage
                    onClick={() => {
                      if (all_permissions?.includes("Add machine")) {
                        setOpenFormModal(true);
                      } else {
                        notifyError("You don't have permission to add.");
                      }
                    }}
                    buttonText={t("machine.add")}
                  />
                )}

                {/* Pagination */}
                <Stack
                  direction="row"
                  sx={{
                    margin: "10px 0",
                    justifyContent: "center",
                    padding: "10px",
                    display: machines?.data?.data?.length > 0 ? "flex" : "none",
                  }}
                  gap={2}
                  justifyContent={"center"}
                >
                  <Pagination
                    count={machines?.data?.last_page || 1}
                    variant="outlined"
                    shape="rounded"
                    value={page}
                    onChange={(e, value) => setPage(value)}
                  />
                </Stack>
              </Card>
            </div>
          </Row>
        </Container>
      </Box>

      {/* Confirmation modal */}
      <CloseModel
        open={confirm.open}
        handleClose={handleClose}
        fun={DeleteMachines}
        id={machineData.id}
        page={page}
        mutateFun={() => {
          queryClient.invalidateQueries({
            queryKey: ["machines"],
          });
        }}
        msg={t("machine.deleted_machine")}
        onSuccessFunction={() => {
          setMachineData({});
        }}
      />

      {/* Create || Edit branch modal */}
      <GlobalModal
        modalHeaderTypography={t("machine.create_machine")}
        open={openFormModal}
        handleClose={() => {
          setOpenFormModal(false);
        }}
      >
        <MachineForm
          handleClose={() => {
            setOpenFormModal(false);
          }}
          canteenId={machineData?.canteen_id || ""}
          machineId={machineData?.id || null}
          branchId={machineData?.branch_id || null}
          initialManagerId={machineData?.manager_id || ""}
          machineName={machineData?.name || ""}
          code={machineData?.code || ""}
          FCM_token={machineData?.FCM_token || ""}
          device_token={machineData?.device_token || ""}
          setMachinesData={setMachineData}
        />
      </GlobalModal>
    </>
  );
}

export default MachinesManagement;

/**
 * Create | Edit Machine
 * 1. Name
 * 2. Code
 * 3. Canteen id
 * 4. Machine id [edit]
 */

function MachineForm({
  handleClose,
  setMachinesData = null,
  machineName = "",
  code = "",
  machineId = "",
  canteenId = "",
  FCM_token = "",
  device_token = "",
}) {
  const [deviceTokenState, setDeviceTokenState] = useState(device_token);
  const [machineNameState, setMachineNameState] = useState(machineName);
  const [canteenIdState, setCanteenIdState] = useState(canteenId);
  const [FCMTokenState, setFCMTokenState] = useState(FCM_token);
  const [codeState, setCodeState] = useState(code);
  const { t } = useTranslation();

  // Get all canteens
  const { data, isLoading: canteensIsLoading } = useQuery({
    queryKey: ["Canteens"],
    queryFn: GetAllCanteens,
  });

  const queryClient = useQueryClient();
  const { mutate } = useMutation({
    mutationFn: machineId ? UpdateMachines : AddMachines,
    onSuccess: (data) => {
      queryClient.invalidateQueries({
        queryKey: ["machines"],
      });
      notifySuccess(
        machineId ? t("machine.machine_updated_success") : t("machine.machine_added_success")
      );
      if (machineId) {
        setMachinesData((prev) => {
          return { ...prev, name: machineNameState, canteen_id: canteenIdState, code: codeState };
        });
      }
      handleClose();
    },
    onError: (err) => {
      console.log(err);
      if (err.response) {
        const status = err.response.status;
        if (status === 422) {
          const errors = err.response.data.errors;
          const errorMessages = [];

          if (errors.name) {
            errorMessages.push(`Name: ${errors.name.join(", ")}`);
          }
          if (errors.manager_id) {
            errorMessages.push(`Manager: ${errors.manager_id.join(", ")}`);
          }

          errorMessages.forEach((message) => {
            notifyError(message);
          });
        } else {
          notifyError(err.message);
          err?.response?.data?.message && notifyError(err?.response?.data?.message);
        }
      } else {
        // console.log("Error", err.message);
      }
    },
  });

  const handleChangeCanteenSelect = (event) => {
    setCanteenIdState(event.target.value);
  };

  const onSubmit = (e) => {
    e.preventDefault();

    const formData = new FormData(e.target);

    const name = formData.get("name");
    const canteenID = formData.get("canteen_id");
    const code = formData.get("code");
    const device_token = formData.get("device_token");
    const FCM_token = formData.get("FCM_token");

    if (
      name.trim() === "" ||
      canteenID.trim() === "" ||
      code.trim() === "" ||
      device_token.trim() === "" ||
      FCM_token.trim() === ""
    ) {
      notifyError(t("machine.machine_required_fields"));
    }

    if (machineId) {
      mutate({ formData, id: machineId });
    } else {
      mutate(formData);
    }
  };

  useEffect(() => {
    // Pre-populate fields with existing branch data when in edit mode
    setCanteenIdState(canteenId);
    setFCMTokenState(FCM_token);
    setDeviceTokenState(device_token);
  }, [machineId, canteenId, FCM_token, device_token]);

  return (
    <form onSubmit={onSubmit} className="py-4">
      {/* Machine name */}
      <TextField
        label={t("machine.machine_name")}
        variant="outlined"
        size="small"
        required
        fullWidth
        className="mb-4"
        name="name"
        type="text"
        defaultValue={machineId ? machineNameState : ""}
        onChange={(event) => {
          setMachineNameState(event.target.value);
        }}
      />

      {/* Code */}
      <TextField
        label={t("machine.code")}
        variant="outlined"
        size="small"
        required
        fullWidth
        className="mb-4"
        name="code"
        type="text"
        defaultValue={machineId ? code : codeState}
        onChange={(event) => {
          setCodeState(event.target.value);
        }}
      />

      {/* Device Token */}
      <TextField
        label={t("machine.device_token")}
        variant="outlined"
        size="small"
        required
        fullWidth
        className="mb-4"
        name="device_token"
        type="text"
        defaultValue={deviceTokenState}
        onChange={(e) => {
          setDeviceTokenState(e.target.value);
        }}
      />

      {/* FCM Token */}
      <TextField
        label={t("machine.fcm_token")}
        variant="outlined"
        size="small"
        required
        fullWidth
        className="mb-4"
        name="FCM_token"
        type="text"
        defaultValue={FCMTokenState}
        onChange={(e) => {
          setFCMTokenState(e.target.value);
        }}
      />

      {/* Canteens */}
      <FormControl fullWidth className="mb-4">
        <InputLabel id="canteen">{t("machine.canteens")}</InputLabel>
        <Select
          labelId={t("machine.canteens")}
          id="selectCanteen"
          value={canteenIdState}
          name="canteen_id"
          label="Canteen"
          onChange={handleChangeCanteenSelect}
          required
          disabled={canteensIsLoading}
        >
          {data?.data?.data?.map((canteen) => (
            <MenuItem value={canteen.id} key={canteen.id}>
              {canteen.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <Button type="submit" variant="contained" color="primary" fullWidth>
        {machineId ? t("machine.update") : t("machine.create")}
      </Button>
    </form>
  );
}
