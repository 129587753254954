import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Modal,
  NativeSelect,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import CloseIcon from "@mui/icons-material/Close";
import { useEffect, useState } from "react";
import axios from "axios";
import Api from "../../config/api";
import { notifyError, notifySuccess } from "../../utilites/toastify";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useTheme } from "@emotion/react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { AddStudent, UpdateStudent } from "../../pages/StudentManagment/api";
import { GetDeparment } from "pages/Department/api";
import { GetEquipment } from "pages/Equipment/api";
import { GetPosition } from "pages/Position/api";
import img1 from "../../assets/img/theme/profile.jpg";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { xs: "400px", sm: "500px", md: "700px", lg: "900px" },
  bgcolor: "background.paper",
  border: "1px solid var(--primary)",
  boxShadow: 24,
  p: 4,
  borderRadius: 3,
  color: "#fff",
};
const AddModel = ({ input, update, page, id, data, open, handleClose }) => {
  const { direction, mode } = useSelector((state) => state.theme);

  const [studentInput, setStudentInput] = useState(input);
  // console.log("input", input);
  // console.log("studentInput", studentInput);

  const [img, setImg] = useState(input?.profile_img || null);
  const [imgInput, setImgInput] = useState();

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    setImgInput(file);

    const imageUrl = URL.createObjectURL(file);
    setImg(imageUrl);
  };
  useEffect(() => {
    setStudentInput(input);
  }, [input]);
  const { data: depData } = useQuery({
    queryKey: ["dep"],
    queryFn: () => GetDeparment(),
  });
  const { data: eqData } = useQuery({
    queryKey: ["eq"],
    queryFn: () => GetEquipment(),
  });
  const { data: posData } = useQuery({
    queryKey: ["pos"],
    queryFn: () => GetPosition(),
  });
  const queryClient = useQueryClient();
  const { mutate: mutateAdd } = useMutation({
    mutationFn: AddStudent,
    onSuccess: (data) => {
      queryClient.invalidateQueries({
        queryKey: ["student"],
      });
      setImgInput(null);
      setImg(null);
      notifySuccess("Student Added");
      handleClose();
    },

    onError: (err) => {
      const errors = err.response.data.errors;
      for (const key in errors) {
        if (errors.hasOwnProperty(key)) {
          notifyError(errors[key]);
        }
      }
    },
  });
  const { mutate: mutateUpdate } = useMutation({
    mutationFn: UpdateStudent,
    onSuccess: (data) => {
      queryClient.invalidateQueries({
        queryKey: ["student"],
      });
      notifySuccess("Student Updated");
      handleClose();
    },
    onError: (err) => {
      const errors = err.response.data.errors;
      for (const key in errors) {
        if (errors.hasOwnProperty(key)) {
          notifyError(errors[key]);
        }
      }
    },
  });
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setStudentInput((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData();
    if (update) {
      if (imgInput) {
        formData.append("profile_image", imgInput);
      }
      Object.keys(studentInput).forEach((key) => {
        formData.append(key, studentInput[key]);
      });
      mutateUpdate({ formData, id });
    } else {
      formData.append("type", "student");

      if (imgInput) {
        formData.append("profile_image", imgInput);
      }
      Object.keys(studentInput).forEach((key) => {
        formData.append(key, studentInput[key]);
      });
      mutateAdd(formData);
    }
  };
  const { t } = useTranslation();
  const theme = useTheme();

  const textFieldStyles = {
    "& .MuiInputBase-input": {
      color: mode === "dark" ? "#fff" : "grey",
    },
    "& .MuiInputLabel-root": {
      color: mode === "dark" ? "#fff" : "grey",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: mode === "dark" ? "#fff" : "grey",
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: mode === "dark" ? "#bbb" : "grey",
    },
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: mode === "dark" ? "#bbb" : "grey",
    },
  };

  return (
    <div style={{ textAlign: "right" }}>
      <Dialog
        // fullScreen
        open={open}
        onClose={handleClose}
        fullWidth
        maxWidth="md"
        sx={{
          //You can copy the code below in your theme

          "& .MuiPaper-root": {
            background: `${mode == "dark" ? "#172B4D" : "white"}`,
          },
          "& .MuiBackdrop-root": {},
        }}
        dir={direction}
      >
        <DialogTitle
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography sx={{ color: theme.palette.text.main }}>
            {update ? t("managment.update") : t("managment.add")}
          </Typography>{" "}
          <IconButton onClick={handleClose} style={{ float: "right" }}>
            <CloseIcon color="primary"></CloseIcon>
          </IconButton>{" "}
        </DialogTitle>
        <DialogContent>
          <form onSubmit={handleSubmit}>
            <Grid sx={{ padding: "10px" }} container spacing={2}>
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <Box>
                  <input
                    style={{ display: "none" }}
                    id="raised-button-file"
                    type="file"
                    onChange={handleImageUpload}
                  />
                  <label htmlFor="raised-button-file">
                    <Button variant="raised" component="span">
                      <Box
                        component="img"
                        sx={{
                          height: "100px",
                          width: "100px",
                          borderRadius: "50%",
                        }}
                        alt="Profile Preview"
                        src={img || img1}
                      />
                    </Button>
                  </label>
                </Box>
              </Grid>{" "}
              <Box>
                <Grid sx={{ padding: "10px" }} container spacing={2}>
                  <Grid item xs={12} sm={6} md={4} lg={3}>
                    <TextField
                      variant="outlined"
                      label={t("managment.name")}
                      name="name"
                      required
                      value={studentInput.name}
                      onChange={handleInputChange}
                      sx={{ width: "100%" }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} lg={3}>
                    <TextField
                      variant="outlined"
                      label={t("managment.stCard")}
                      name="user_card"
                      value={studentInput.user_card}
                      onChange={handleInputChange}
                      required
                      disabled={update}
                      sx={{ width: "100%" }}
                      inputProps={{ type: "number" }}
                    />
                  </Grid>
                  {!update && (
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                      <TextField
                        variant="outlined"
                        label={t("managment.num")}
                        name="user_number"
                        value={studentInput.user_number}
                        onChange={handleInputChange}
                        required
                        inputProps={{ type: "number" }}
                      />
                    </Grid>
                  )}
                  <Grid item xs={12} sm={6} md={4} lg={3}>
                    <TextField
                      variant="outlined"
                      label={t("managment.phone")}
                      name="user_phone"
                      value={studentInput.user_phone}
                      onChange={handleInputChange}
                      required
                      sx={{ width: "100%" }}
                      inputProps={{ type: "number" }}
                    />
                  </Grid>

                  {/* Department */}
                  <Grid item xs={12} sm={6} md={4} lg={3}>
                    <FormControl required variant="outlined" fullWidth>
                      <InputLabel htmlFor="department-select">{t("managment.dep")}</InputLabel>
                      <Select
                        name="department"
                        value={
                          depData?.find((dep) => dep.id === studentInput?.department)?.id || ""
                        }
                        onChange={handleInputChange}
                        label={t("managment.dep")}
                        id="department-select"
                        required
                      >
                        <MenuItem value={""}>None</MenuItem>

                        {depData?.map((dep) => (
                          <MenuItem key={dep?.id} value={dep?.id}>
                            {dep?.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>

                  {update && (
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                      <FormControl required variant="outlined" fullWidth>
                        <InputLabel>{t("managment.status")}</InputLabel>
                        <Select
                          name="status"
                          value={studentInput?.status || ""}
                          onChange={handleInputChange}
                          label={t("managment.status")}
                          required
                        >
                          <MenuItem value={""}>None</MenuItem>
                          {[
                            { id: 32, value: "normal card" },
                            {
                              id: 33,
                              value: "card that has not been completely cleared",
                            },
                            { id: 64, value: "cancelled card" },
                            { id: 96, value: "reported lost card" },
                            {
                              id: 97,
                              value: "card that has not been completely lost",
                            },
                            { id: 160, value: "recovered card" },
                          ]?.map((pos, index) => (
                            <MenuItem value={pos?.id} key={index}>
                              {pos?.value}
                            </MenuItem>
                          ))}{" "}
                        </Select>
                      </FormControl>
                    </Grid>
                  )}
                  {/*<Grid item xs={12} sm={6} md={4} lg={3}>*/}
                  {/*  <FormControl required variant="outlined" fullWidth>*/}
                  {/*    <InputLabel>{t("managment.eq")}</InputLabel>*/}
                  {/*    <Select*/}
                  {/*      name="equipment"*/}
                  {/*      value={*/}
                  {/*        eqData?.find(*/}
                  {/*          (eq) => eq.id == studentInput?.equipment*/}
                  {/*        )*/}
                  {/*          ? studentInput?.equipment*/}
                  {/*          : null*/}
                  {/*      }*/}
                  {/*      onChange={handleInputChange}*/}
                  {/*      label={t("managment.eq")}*/}
                  {/*      required*/}
                  {/*    >*/}
                  {/*      <MenuItem value={null}>None</MenuItem>*/}
                  {/*      {eqData?.map((eq) => (*/}
                  {/*        <MenuItem value={eq?.id}>{eq?.name}</MenuItem>*/}
                  {/*      ))}{" "}*/}
                  {/*    </Select>*/}
                  {/*  </FormControl>*/}
                  {/*</Grid>*/}
                  {/* <Grid item xs={12} sm={6} md={4} lg={3}>
                <TextField
                  variant="outlined"
                  label={t("managment.email")}
                  name="email"
                  type="email"
                  value={studentInput.email}
                  onChange={handleInputChange}
                />
                </Grid> */}
                  <Grid item xs={12} sm={6} md={4} lg={3}>
                    <TextField
                      variant="outlined"
                      sx={{ width: "100%" }}
                      label={t("managment.id")}
                      name="identity_id"
                      value={studentInput.identity_id}
                      onChange={handleInputChange}
                    />
                  </Grid>
                </Grid>
              </Box>
              {/* Submit Button */}
              <Grid item xs={12}>
                <Button
                  fullWidth
                  sx={{
                    color: "#fff",
                    backgroundColor: "#1AE584",
                    transition: ".4s",
                    "&:hover": {
                      backgroundColor: "#1AE584",
                      opacity: ".8",
                    },
                  }}
                  variant="contained"
                  type="submit"
                >
                  {t("Submit")}
                </Button>
              </Grid>
            </Grid>
          </form>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default AddModel;
