import React, { useEffect, useState } from 'react';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Button, Stack, Box, Grid, Checkbox, FormControlLabel } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { notifySuccess, notifyError } from 'utilites/toastify';
import { getUsers } from '../../redux/slices/user.slice';
import Api from "../../config/api";
import useUserPermissions from 'hooks/useUserPermissions';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: { xs: "90%", sm: "80%", md: "70%", lg: "60%", xl: "50%" }, // Responsive width
    bgcolor: 'background.paper',
    border: '1px solid var(--primary)',
    boxShadow: 24,
    p: 4,
    borderRadius: 3,
    maxHeight: '80vh', // Allow scrolling if too much content
    overflowY: 'auto',  // Scroll if content overflows
    color: "#fff"
};

// eslint-disable-next-line react/prop-types
const PermissionsModal = ({ open, handleClose, update, data, id }) => {
    const { mode } = useSelector((state) => state.theme);
    const  all_permissions  = useUserPermissions(); // Flat permissions list from state
    const [selectedPermissions, setSelectedPermissions] = useState([]);

    const dispatch = useDispatch();
    const { t } = useTranslation();

    useEffect(() => {
        if (data && data?.length) {
            setSelectedPermissions(data); // Set initial permissions for the user being edited
        }
    }, [data]);

    // Handle checkbox changes for permission selection
    const handlePermissionChange = (event) => {
        const value = event.target.name;
        setSelectedPermissions((prev) =>
            prev.includes(value)
                ? prev.filter((item) => item !== value)
                : [...prev, value]
        );
    };

    // Handle form submission for permission updates
    const handleSubmit = (e) => {
        e.preventDefault();
        const values = { permissions: selectedPermissions };

        Api.post(`api/users/user/${id}/permissions`, values)
            .then(() => {
                notifySuccess("User has been " + (update ? "Updated" : "Added"));
                handleClose();
                dispatch(getUsers(1)); // Reload users after permission update
            })
            .catch((err) => {
                notifyError("Failed to update user permissions");
            });
    };

    return (
        <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
            <Box sx={style} className={`${mode === "dark" ? "bg-default" : ""}`}>
                <Typography
                    id="modal-modal-title"
                    variant="h6"
                    component="h2"
                    sx={{
                        fontSize: "1.4rem",
                        fontWeight: "700",
                        marginBottom: "20px",
                        color: mode === "dark" ? "#fff" : "#000",
                    }}
                >
                    {t("user.editPermission")}
                </Typography>

                <form onSubmit={handleSubmit}>
                    <Grid container spacing={2}>
                        {/* Dynamically render permissions */}
                        {all_permissions.map((permission, index) => (
                            <Grid key={index} item xs={12} sm={6} md={4} lg={3}>
                                <Stack direction={"column"} sx={{ marginTop: "6px" }}>
                                    <FormControlLabel
                                        sx={{ color: mode === "dark" ? "#fff" : "gray" }}
                                        control={
                                            <Checkbox
                                                checked={selectedPermissions?.includes(permission)} // Ensure we're checking by permission name
                                                onChange={handlePermissionChange}
                                                name={permission} // Use the permission name as the identifier
                                            />
                                        }
                                        label={permission} // Display the permission name
                                    />
                                </Stack>
                            </Grid>
                        ))}
                    </Grid>

                    <Stack direction="row" gap={2} mt={2} justifyContent={"center"}>
                        <Button type="submit" variant="contained" color="primary" sx={{ color: "#fff" }}>
                            {t("Submit")}
                        </Button>
                        <Button onClick={handleClose} variant="outlined" color="secondary">
                            {t("Cancel")}
                        </Button>
                    </Stack>
                </form>
            </Box>
        </Modal>
    );
};

export default PermissionsModal;
