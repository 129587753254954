export const themeSetting = (dir, mode) => {
  
  return {
    direction: dir,

    palette: {
      mode: mode,
      ...(mode === "dark"
        ? {
            primary: { main: "#1AE584" },
            paper: "#1A2537",

            background: { main: "#1A2537", secondary: "#1F2A3D" },
            text: { main: "#DDDFE1" },
          }
        : {
            primary: { main: "#1AE584" },
            paper: "#FFFFFF",

            background: { main: "#FFFFFF", secondary: "#F4F7FB" },
            text: { main: "#29343D", secondary: "#87939D" },
          }),
    },
  };
};

export default themeSetting;
