import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Api from "../../config/api";

export const getUserData = createAsyncThunk("user/getUserData", async (_, thunkAPI) => {
  try {
    const response = await Api.get("/api/users/user");
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.message);
  }
});


export const getUsers = createAsyncThunk("user/getUsers", async (page = 1, thunkAPI) => {
  try {
    const res = await Api.get("api/users/system_user", {
      params: {
        per_page: 10,
        page: page,
      },
    });
    return res.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.message);
  }
});

export const getRoles = createAsyncThunk("user/getRoles", async (page = 1, thunkAPI) => {
  try {
    const res = await Api.get("api/roles-permissions");
    return res.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.message);
  }
});

export const getParents = createAsyncThunk("user/getParents", async (page = 1, thunkAPI) => {
  try {
    const res = await Api.get("api/users/parent", {
      params: {
        per_page: 10,
        page: page,
      },
    });
    return res.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.message);
  }
});

export const userSlice = createSlice({
  name: "user",
  initialState: {
    data: {},
    logedin: false,
    users: [],
    page: 1,
    all_permissions: [],
    roles: [],
    parents: [],
    count: 0,
    parentCount: 0,
  },
  reducers: {
    loginUser: (state, action) => {
      state.logedin = true;
      let { user } = action.payload;
      state.data = user;

      const user_permissions = user?.all_permissions.map((permission) => permission.name);
      user_permissions?.push("Dashboard");
      user_permissions?.push("Logout");

      state.all_permissions = user_permissions;
      state.roles = user?.roles;
    },
    logout: (state) => {
      state.logedin = false;
      state.data = {};
      state.permissions = [];
      state.roles = [];
      state.parents = [];
      state.all_permissions = [];
    },
    handleIncrement: (state) => {
      state.page += 1;
    },
    handleDecrement: (state) => {
      state.page = state.page > 1 ? state.page - 1 : state.page;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getUserData.fulfilled, (state, action) => {
      state.data = action.payload;
      state.logedin = true;
    });
    builder.addCase(getUserData.rejected, (state, action) => {
      if (!state.data || !localStorage.getItem("access_token")) {
        state.logedin = false;
      }
    });
    builder.addCase(getUsers.fulfilled, (state, action) => {
      state.users = action.payload?.data;
      state.count = state.users.length;
    });
    builder.addCase(getParents.fulfilled, (state, action) => {
      state.parents = action.payload?.data;
      state.parentCount = state.parents.length;
    });
    builder.addCase(getRoles.fulfilled, (state, action) => {
      state.all_roles = action.payload?.roles;
    });
    builder.addCase(getUsers.rejected, (state, action) => {
      state.users = [];
      state.count = 0;
    });
    builder.addCase(getParents.rejected, (state, action) => {
      state.parents = [];
      state.parentCount = 0;
    });
  },
});

export const { loginUser, logout, handleIncrement, handleDecrement } = userSlice.actions;
export default userSlice.reducer;
