import React, { useEffect, useState } from "react";
import styles from "./OTP.module.css";
import { Link, useLocation, useNavigate } from "react-router-dom";
import WestIcon from "@mui/icons-material/West";
import OTPInput from "react-otp-input";
import { resendOtp, verifyRequest } from "./api";
import { notifySuccess } from "utilites/toastify";
import { notifyError } from "utilites/toastify";
import { CircularProgress } from "@mui/material";
function OTP() {
  const [otp, setOtp] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const location = useLocation();
  const navigate = useNavigate();
  // console.log(location.state?.email);

  const handleResendOTP = async () => {
    const res = await resendOtp({ email: location.state?.email });

    setLoading(true);

    if (res.status === 200) {
      setLoading(false);
      notifySuccess(`${res?.data?.message}`);
      return;
    }
  };

  const handelSubmit = async (e) => {
    e.preventDefault();

    if (!otp) {
      setError("Please enter a valid OTP.");
      return; // Prevent form submission if otp is invalid
    }

    // Reset error if otp is valid
    setError("");
    setLoading(true);

    const res = await verifyRequest({ email: location.state?.email, otp: otp });
    // console.log(res);

    if (res.status === 200) {
      setLoading(false);
      notifySuccess(`${res?.data?.message}`);
      setTimeout(() => {
        navigate("/auth/forgot-password/new-password", {
          state: {
            email: location.state?.email,
            verification_token: res?.data?.verification_token,
          },
          replace: true, // Replace the current URL in the browser history
        });
      }, 500);
      return;
    } else if (res?.status === 422) {
      setError(res?.data?.message);
      setLoading(false);
      return;
    } else if (res?.status === 500) {
      notifyError("Ops something went wrong internal server error 500!");
      setLoading(false);
      return;
    } else {
      notifyError(res?.data?.message);
      setLoading(false);
      return;
    }
  };

  useEffect(() => {
    if (!location.state?.email) {
      // Redirect to first step if no email is present in state
      navigate("/auth/forgot-password", { replace: true });
    }
  }, [location.state, navigate]);

  return (
    <div className={styles.card}>
      <h1>Verify</h1>
      <p className={`mt-1 mb-4 ${styles.message}`}>
        Please enter the 6-digit code send to you at {location.state?.email}.
      </p>
      <form action="" className="mb-4" onSubmit={handelSubmit}>
        <div className="mb-3">
          <OTPInput
            containerStyle={`${styles.otp_input} mb-1`}
            value={otp}
            onChange={setOtp}
            numInputs={6}
            renderSeparator={<span>-</span>}
            renderInput={(props) => <input {...props} />}
          />
          {error && <span className="text-danger text-sm">{error}</span>}
        </div>

        <button
          type="submit"
          disabled={loading}
          className={`py-2 px-3 text-white w-100  ${styles.send_button}`}
        >
          {loading ? <CircularProgress size={25} /> : "Verify"}
        </button>
      </form>

      <div className="d-flex align-items-center justify-content-between">
        <div className="d-flex align-items-center">
          <WestIcon sx={{ color: "#020817", fontSize: "15px" }} />
          <p className={`mb-0 ${styles.login_link}`}>
            Back to{" "}
            <Link to={"/auth/login"} className={`text-underline`}>
              Login
            </Link>
          </p>
        </div>

        <button
          type="button"
          disabled={loading}
          className={`${styles.resend}`}
          onClick={handleResendOTP}
        >
          Resend OTP
        </button>
      </div>
    </div>
  );
}

export default OTP;
