import Api from "config/api";

export const GetCanteens = async (page) => {
  try {
    const res = await Api.get("api/canteens", {
      params: {
        per_page: 10,
        page: page,
      },
    });
    return res.data;
  } catch (error) {
    // console.error("Error fetching ser data:", error);
    return error;
  }
};

export const AddCanteen = async (data) => {
  const res = await Api.post("api/canteens/store", data);
  return res.data;
};

export const UpdateCanteen = async (data) => {
  const res = await Api.post(`api/canteens/update/${data.id}`, data.formData);
  return res.data;
};

export const DeleteCanteen = async ({ id }) => {
  const res = await Api.delete(`api/canteens/delete/${id}`);
  return res.data;
};

export const GetAllManagers = async () => {
  try {
    const res = await Api.get("api/branches/managers");
    return res.data;
  } catch (error) {
    return error;
  }
};
export const GetAllBranches = async () => {
  try {
    const res = await Api.get("api/branches");
    return res.data;
  } catch (error) {
    return error;
  }
};
