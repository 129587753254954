import { Box, Button, CircularProgress, IconButton, Pagination, Stack } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Card, CardHeader, Table, Container, Row } from "reactstrap";

import HttpsIcon from "@mui/icons-material/Https";
// core components
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useQuery } from "@tanstack/react-query";
import { DeleteStudent, GetStudent } from "./api";
import AddModel from "components/Modal/AddUserModal";
import PermissionsModal from "components/Modal/PermissionsModal";
import { getUsers } from "../../redux/slices/user.slice";
import CloseModel from "components/Modal/DeleteDialouge";
import Checkbox from "@mui/material/Checkbox";

import { notifyError } from "utilites/toastify";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import BreadCrumbs from "components/BreadCrumbs";
import useUserPermissions from "hooks/useUserPermissions";
import EmptyContentMessage from "components/EmptyContentMessage/EmptyContentMessage";

export default function StudentManagment() {
  const { mode } = useSelector((state) => state.theme);
  const [page, setPage] = useState(1);
  const [confirm, setConfirm] = useState({ open: false, id: "" });
  const [permission, setPermission] = useState({ open: false, id: "", data: {} });
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { users } = useSelector((state) => state.user);
  const [modal, setModal] = useState({
    data: {
      name: "",
      email: "",
      password: "",
      user_phone: "",
      position: "",
      type: "system_user",
    },
    open: false,
    update: false,
  });

  const all_permissions = useUserPermissions();

  useEffect(() => {
    dispatch(getUsers(page));
  }, [page]);

  const { data, isLoading } = useQuery({
    queryKey: ["student", { page }],
    queryFn: () => GetStudent(page),
  });

  function handleClose() {
    setConfirm({ open: false, id: "" });
    setStudentData({});
    setModal({ open: false, update: false, data: {} });
  }

  const [studentData, setStudentData] = useState({});
  function handleCheck(student) {
    if (studentData.id == student.id) {
      setStudentData({});
    } else {
      setStudentData(student);
    }
  }

  function openEdit() {
    if (!studentData.id) return notifyError("You must select user first");
    setModal({ update: true, open: true, data: studentData });
  }

  function openDelete() {
    if (!studentData.id) return notifyError("You must select user first");
    setConfirm({ open: true });
  }

  return (
    <>
      <Box sx={{ minHeight: "calc( 100vh - 123px) " }}>
        {/* Page content */}

        <Container className="" fluid>
          {/* Table */}
          <BreadCrumbs pageName={t("managment.user")} />
          <Row>
            <div className="col">
              <Card style={{ backgroundColor: mode == "dark" ? "#1F2A37" : "" }}>
                <CardHeader className={`border-0 bg-${mode != "dark" || "transparent"}`}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <Box>
                      <h3 className={`mb-0 text-${mode == "light" ? "black" : "white"}`}>
                        {t("managment.user")}
                      </h3>
                      {/* <Typography
                        sx={{
                          color: "gray",
                          marginBottom: "16px",
                          marginTop: "8px",
                        }}
                        variant="subtitle2"
                      >
                        create,read,update,or delete equipmant , department ,
                        and position
                      </Typography> */}
                    </Box>
                    <Stack direction={"row"} spacing={2}>
                      {all_permissions?.includes("Add user") && (
                        <Button
                          sx={{
                            color: "black",
                            backgroundColor: "#fff",
                            fontSize: "15px",
                            transition: ".4s",
                            display: "flex",
                            gap: "5px",
                            boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",

                            padding: "8px 10px",
                            borderRadius: "10px",
                            textTransform: "none",
                            "&:hover": {
                              backgroundColor: "#fff",
                              opacity: ".8",
                            },
                          }}
                          onClick={() => setModal({ open: true, update: false })}
                          className={`${mode == "dark" ? "dark-button-man" : ""}`}
                        >
                          <AddCircleIcon sx={{ color: "#71DCD4" }} />
                          {t("managment.addUser")}
                        </Button>
                      )}
                      {all_permissions?.includes("Update user") && (
                        <Button
                          sx={{
                            color: "black",
                            backgroundColor: "#fff",
                            fontSize: "15px",
                            transition: ".4s",
                            display: "flex",
                            gap: "5px",
                            boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",

                            padding: "8px 10px",
                            borderRadius: "10px",
                            textTransform: "none",
                            "&:hover": {
                              backgroundColor: "#fff",
                              opacity: ".8",
                            },
                          }}
                          onClick={openEdit}
                          className={`${mode == "dark" ? "dark-button-man" : ""}`}
                        >
                          <EditIcon /> {t("edit")}
                        </Button>
                      )}
                      {all_permissions?.includes("Delete user") && (
                        <Button
                          sx={{
                            color: "black",
                            backgroundColor: "#fff",
                            fontSize: "15px",
                            transition: ".4s",
                            display: "flex",
                            gap: "5px",
                            boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",

                            padding: "8px 10px",
                            borderRadius: "10px",
                            textTransform: "none",
                            "&:hover": {
                              backgroundColor: "#fff",
                              opacity: ".8",
                            },
                          }}
                          onClick={openDelete}
                          className={`${mode == "dark" ? "dark-button-man" : ""}`}
                        >
                          <DeleteIcon sx={{ color: "#C81E1E" }} /> {t("del")}
                        </Button>
                      )}
                    </Stack>
                  </Box>
                </CardHeader>
                {isLoading ? (
                  <Box sx={{ display: "flex", justifyContent: "center" }}>
                    <CircularProgress />
                  </Box>
                ) : users?.length > 0 ? (
                  <Table className={`align-items-center  table-${mode} table-flush`} responsive>
                    <thead
                      style={{
                        backgroundColor: mode == "dark" ? "#374151" : "#F9FAFB",
                        color: "#6B7280",
                      }}
                    >
                      <tr>
                        <th style={{ textAlign: "center", width: "8px" }} scope="col"></th>
                        <th
                          style={{
                            textAlign: "center",
                            padding: "20px ",
                            fontSize: "12px",
                          }}
                          scope="col"
                        >
                          {t("managment.name")}
                        </th>
                        <th
                          style={{
                            textAlign: "center",
                            padding: "20px ",
                            fontSize: "12px",
                          }}
                          scope="col"
                        >
                          {t("managment.email")}
                        </th>
                        <th
                          style={{
                            textAlign: "center",
                            padding: "20px ",
                            fontSize: "12px",
                          }}
                          scope="col"
                        >
                          {t("managment.phone")}
                        </th>
                        <th
                          style={{
                            textAlign: "center",
                            padding: "20px ",
                            fontSize: "12px",
                          }}
                          scope="col"
                        >
                          {t("roles.name")}
                        </th>
                        {all_permissions?.includes("Update user") && (
                          <th
                            style={{
                              textAlign: "center",
                              padding: "20px ",
                              fontSize: "12px",
                            }}
                            scope="col"
                          >
                            {t("user.permission")}
                          </th>
                        )}
                        {/* <th scope="col">{t("managment.role")}</th>
                      <th scope="col">{t("managment.type")}</th> */}
                        {/* {permissions?.includes("Update user") && <th scope="col">{t("edit")}</th>} */}
                        {/* {permissions?.includes("Delete user") && <th scope="col">{t("del")}</th>} */}
                      </tr>
                    </thead>
                    {all_permissions?.includes("All users") && (
                      <tbody
                        style={{
                          backgroundColor: mode == "dark" ? "#1F2A37" : "white",
                        }}
                      >
                        {users?.map((student, index) => (
                          <tr key={index}>
                            <td
                              style={{
                                textAlign: "center",
                                width: "8px",
                                padding: "0",
                              }}
                            >
                              {" "}
                              <Checkbox
                                onChange={() => handleCheck(student)}
                                checked={studentData?.id === student.id}
                              />
                            </td>
                            <td style={{ textAlign: "center" }} scope="row">
                              {student?.name || "-"}
                            </td>
                            <td style={{ textAlign: "center" }}>{student?.email || "-"}</td>
                            <td style={{ textAlign: "center" }}>{student?.user_phone || "-"}</td>
                            <td style={{ textAlign: "center" }}>{student?.roles || "-"}</td>
                            {all_permissions?.includes("Update user") && (
                              <td style={{ textAlign: "center" }}>
                                <IconButton
                                  onClick={() =>
                                    setPermission({
                                      open: true,
                                      id: student.id,
                                      data: student?.permissions,
                                    })
                                  }
                                  sx={{
                                    backgroundColor: "#5e72e4",
                                    color: "white",
                                  }}
                                >
                                  <HttpsIcon />
                                </IconButton>
                              </td>
                            )}
                            {/* {permissions?.includes("Update user") && <td style={{ textAlign: "center" }}>
                            <AddModel
                              input={studentData}
                              update={true}
                              page={page}
                              id={studentData?.id}
                              data={studentData}
                            />
                          </td>} */}
                            {/* {permissions?.includes("Delete user") && <td>
                            <IconButton onClick={() => setConfirm({ open: true, id: student?.id })} sx={{ backgroundColor: "red", color: "white" }}>
                              <DeleteIcon />
                            </IconButton>
                          </td>} */}
                          </tr>
                        ))}
                      </tbody>
                    )}
                  </Table>
                ) : (
                  <EmptyContentMessage
                    onClick={() => {
                      if (all_permissions?.includes("Add user")) {
                        setModal({ open: true, update: false });
                      } else {
                        notifyError("You don't have permission to add.");
                      }
                    }}
                    buttonText={t("managment.addUser")}
                  />
                )}
                <Stack
                  direction="row"
                  sx={{
                    margin: "10px 0",
                    display: users?.length > 0 ? "flex" : "none",
                  }}
                  gap={2}
                  justifyContent={"center"}
                >
                  <Pagination
                    count={data?.last_page || 1}
                    variant="outlined"
                    shape="rounded"
                    value={page}
                    onChange={(e, value) => setPage(value)}
                  />
                </Stack>
                {/* </nav>
                </CardFooter> */}
              </Card>
            </div>
          </Row>
        </Container>
      </Box>
      <CloseModel
        open={confirm.open}
        handleClose={handleClose}
        fun={DeleteStudent}
        id={studentData.id}
        page={page}
        update={getUsers}
        msg={t("user.deleteUser")}
      />
      <AddModel
        input={modal.data}
        update={modal.update}
        page={page}
        id={modal?.data?.id}
        data={modal.data}
        open={modal.open}
        handleClose={handleClose}
      />
      <PermissionsModal
        open={permission.open}
        handleClose={() => setPermission({ open: false, id: "" })}
        data={permission.data}
        id={permission.id}
      />
    </>
  );
}
