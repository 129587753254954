import { Box } from "@mui/material";
import AdminNavbar from "components/Navbars/AdminNavbar.js";
import Sidebar from "components/Sidebar/Sidebar.js";
import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import routes from "routes.js";
import { getUserData } from "../redux/slices/user.slice";
import useUserPermissions from "hooks/useUserPermissions";

const Admin = (props) => {
  const { mode, direction } = useSelector((state) => state.theme);
  const permissions = useUserPermissions();
  const mainContent = useRef(null);
  const location = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    mainContent.current.scrollTop = 0;
  }, [location]);

  useEffect(() => {
    dispatch(getUserData());
  }, []);

  const getRoutes = (routes) => {
    return routes.map((route, key) => {
      // Skip the route if its name is "logout"
      if (route.layout?.toLowerCase() === "/auth") return null;

      if (permissions?.includes(route.permission_key)) {
        if (route.children) {
          // Render child routes that match user permissions
          return route.children.map((subRoute, childKey) =>
            permissions?.includes(subRoute.permission_key) ? (
              <Route key={childKey} path={subRoute.path} element={subRoute.component} exact />
            ) : null
          );
        } else {
          // Return a Route for the parent without children
          return <Route path={route.path} element={route.component} key={key} exact />;
        }
      }

      // Skip routes that are outside of user permission.
      return null;
    });
  };

  const getBrandText = (path) => {
    for (let i = 0; i < routes.length; i++) {
      if (props?.location?.pathname.indexOf(routes[i].layout + routes[i].path) !== -1) {
        return routes[i].name;
      }
    }
    return "Brand";
  };

  return (
    <Box
      dir={direction}
      sx={{
        backgroundColor: `${mode == "light" ? "#ECF9F2" : "#1A2537"}`,
        color: `${mode == "light" ? "black" : "white"}`,
      }}
    >
      <Sidebar
        {...props}
        routes={routes}
        logo={{
          innerLink: "/admin/index",
          imgSrc: require("../assets/img/brand/argon-react.png"),
          imgAlt: "...",
        }}
      />
      <div className="main-content mt-0" ref={mainContent}>
        <AdminNavbar {...props} brandText={getBrandText(props?.location?.pathname)} />
        <Routes>
          {getRoutes(routes)}
          <Route path="*" element={<Navigate to="/admin/index" replace />} />
        </Routes>
        {/* <Container fluid>
          <AdminFooter />
        </Container> */}
      </div>
    </Box>
  );
};

export default Admin;
