import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { notifySuccess } from "utilites/toastify";

function useClipboard(defaultText = "") {
  const { t } = useTranslation();
  const copyToClipboard = useCallback(
    (text = defaultText) => {
      if (navigator.clipboard) {
        navigator.clipboard
          .writeText(text)
          .then(() => {
            notifySuccess(t("copied_to_clipboard"));
          })
          .catch((err) => {
            console.error("Failed to copy text: ", err);
          });
      } else {
        console.error("Clipboard API not supported");
      }
    },
    [defaultText, t]
  );

  return copyToClipboard;
}

export default useClipboard;
