import Api from "config/api";

export const UpdatePasswordRequest = async (data) => {
  try {
    const request = await Api.post("/api/users/password/reset/update", data);
    // console.log("request: ", request);
    return request;
  } catch (error) {
    return error.response;
  }
};
