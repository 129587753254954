import React, { useEffect, useState } from "react";
import { GetUserDetails, UpdateUserDetails } from "./api";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { useTheme } from "@emotion/react";
import styles from "./ProfileTab.module.css";
import { notifySuccess } from "utilites/toastify";
import { notifyError } from "utilites/toastify";
import { useTranslation } from "react-i18next";
import { getUserData } from '../../redux/slices/user.slice';
import {useDispatch} from "react-redux";
function ProfileTab() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [gender, setGender] = useState("");
  const [imageError, setImageError] = useState("");
  const [nameError, setNameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [genderError, setGenderError] = useState("");
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const theme = useTheme();
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  // Create useQuery to fetch the user details
  const { data } = useQuery({
    queryKey: ["user_details"],
    queryFn: GetUserDetails,
  });

  const throwErrorsObject = {
    name: setEmailError,
    email: setEmailError,
    user_phone: setPhoneError,
    gender: setGenderError,
    profile_image: setImageError,
  };

  async function updateProfileRequest(formData) {
    setLoading(true);

    try {
      const res = await UpdateUserDetails(formData);
      if (res?.status === 200) {
        notifySuccess(t("profile.user_details_updated_successfully"));

        // Refetch user details after update
        queryClient.invalidateQueries({
          queryKey: ["user_details"],
        });

        dispatch(getUserData());
      } else if (res?.status === 422) {
        Object.keys(res.data.errors).forEach((key) => {
          throwErrorsObject[key](res.data.errors[key][0]);
        });
      } else {
        notifyError(t("profile.error_updating_profile"));
      }
    } catch (error) {
      notifyError(t("profile.error_updating_profile"));
    } finally {
      setLoading(false);
    }
  }


  // handle submit form
  const handleSubmit = async (e) => {
    e.preventDefault();
    const formDate = new FormData(e.target);
    const updated_image = formDate.get("profile_image");
    const updated_name = formDate.get("name");
    const updated_email = formDate.get("email");
    const updated_phone = formDate.get("user_phone");
    const updated_gender = formDate.get("gender");

    const validImageTypes = ["image/jpeg", "image/png", "image/webp", "image/avif"];
    if (updated_image.name === "" && updated_image.size === 0) {
      formDate.delete("profile_image");
    } else if (updated_image.name !== "" && !validImageTypes.includes(updated_image.type)) {
      setImageError(t("profile.invalid_image_format"));
      formDate.delete("profile_image");
    } else {
      setImageError("");
    }

    // Name validation
    if (updated_name === "") {
      setNameError(t("profile.name_cannot_be_empty"));
    } else if (updated_name === data?.user?.name) {
      formDate.delete("name");
    } else {
      setNameError("");
    }

    // Email validation
    if (updated_email === "") {
      setEmailError(t("profile.email_cannot_be_empty"));
    } else if (updated_email === data?.user?.email) {
      formDate.delete("email");
    } else {
      setEmailError("");
    }

    // Phone validation
    if (updated_phone === "") {
      setPhoneError(t("profile.phone_cannot_be_empty"));
    } else if (updated_phone === data?.user?.user_phone) {
      formDate.delete("user_phone");
    } else {
      setPhoneError("");
    }

    // Gender validation
    if (updated_gender === "") {
      setGenderError(t("profile.gender_cannot_be_empty"));
    } else if (updated_gender === data?.user?.gender) {
      formDate.delete("gender");
    } else {
      setGenderError("");
    }

    if (
      !formDate.get("name") &&
      !formDate.get("email") &&
      !formDate.get("user_phone") &&
      !formDate.get("gender") &&
      !formDate.get("profile_image")
    ) {
      console.log("here");
      notifyError(t("profile.no_changes_detected_to_update"));
      return;
    } else {
      await updateProfileRequest(formDate);
    }
  };

  useEffect(() => {
    if (data?.user) {
      setName(data.user.name);
      setEmail(data.user.email);
      setPhone(data.user.user_phone);

      if (data.user.gender) setGender(data.user.gender);
    }
  }, [data]);

  return (
    <div>
      <Typography
        variant="h1"
        sx={{
          color: theme.palette.text.main,
          fontSize: "45px",
          marginBottom: "24px",
          textAlign: "start",
        }}
      >
        {t("profile.profile")}
      </Typography>

      {/* Form to show user data and also to update any field */}
      <form action="" onSubmit={handleSubmit}>
        {/* Image */}
        <div className={`${styles.input_control}`}>
          <label htmlFor="image" className="d-block" style={{ textAlign: "start" }}>
            {t("profile.profile_image")}
          </label>
          <input type="file" name="profile_image" className="mb-1" />
          {imageError && <p className="text-danger text-sm">{imageError}</p>}
        </div>

        {/* User name */}
        <div className={`${styles.input_control}`}>
          <TextField
            sx={{ marginBottom: "4px" }}
            id="user_name"
            name="name"
            label={t("profile.name")}
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          {nameError && <p className="text-danger text-sm">{nameError}</p>}
        </div>

        {/* User email */}
        <div className={`${styles.input_control}`}>
          <TextField
            sx={{ marginBottom: "4px" }}
            id="user_email"
            name="email"
            label={t("profile.email")}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          {emailError && <p className="text-danger text-sm">{emailError}</p>}
        </div>

        {/* User phone */}
        <div className={`${styles.input_control}`}>
          <TextField
            sx={{ marginBottom: "4px" }}
            id="user_phone"
            name="user_phone"
            label={t("profile.phone")}
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
          />
          {phoneError && <p className="text-danger text-sm">{phoneError}</p>}
        </div>

        {/* Gender */}
        <div className={`${styles.input_control}`}>
          <FormControl fullWidth sx={{ marginBottom: "4px" }}>
            <InputLabel id="gender-select-label">{t("profile.gender")}</InputLabel>
            <Select
              labelId="gender-select-label"
              value={gender}
              name="gender"
              label={t("profile.gender")}
              onChange={(e) => setGender(e.target.value)}
            >
              <MenuItem value={"male"}>{t("profile.male")}</MenuItem>
              <MenuItem value={"female"}>{t("profile.female")}</MenuItem>
            </Select>
          </FormControl>
          {genderError && <p className="text-danger text-sm">{genderError}</p>}
        </div>

        {/* Button submit */}
        <Button
          variant="contained"
          type="submit"
          sx={{ width: "100%", color: "#fff", padding: "10px 15px" }}
          disabled={loading}
        >
          {t("profile.update")}
        </Button>
      </form>
    </div>
  );
}

export default ProfileTab;
