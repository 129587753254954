import DeleteIcon from "@mui/icons-material/Delete";
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Pagination,
  Select,
  Stack,
  TextField,
} from "@mui/material";
import { useEffect, useState } from "react";
import { Card, CardHeader, Container, Row, Table } from "reactstrap";
// core components
import AddCircleIcon from "@mui/icons-material/AddCircle";
import EditIcon from "@mui/icons-material/Edit";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import BreadCrumbs from "components/BreadCrumbs";
import GlobalModal from "components/GlobalModal/GlobalModal";
import CloseModel from "components/Modal/DeleteDialouge";
import useThemeMode from "hooks/useThemeMode";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { notifyError, notifySuccess } from "utilites/toastify";
import {
  AddCanteen,
  DeleteCanteen,
  GetAllBranches,
  GetAllManagers,
  GetCanteens,
  UpdateCanteen,
} from "./api";
import useUserPermissions from "hooks/useUserPermissions";
import EmptyContentMessage from "components/EmptyContentMessage/EmptyContentMessage";

function CanteensManagement() {
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const [page, setPage] = useState(1);
  const [openFormModal, setOpenFormModal] = useState(false);
  const [confirm, setConfirm] = useState({ open: false, id: "" });
  const [canteenData, setCanteenData] = useState({});

  const { mode } = useSelector((state) => state.theme);
  const themMode = useThemeMode(mode);
  const all_permissions = useUserPermissions();

  // Get all canteens
  const { data: canteens, isLoading } = useQuery({
    queryKey: ["canteens", { page }],
    queryFn: GetCanteens,
  });

  function handleClose() {
    setConfirm({ open: false, id: "" });
  }

  function handleCheck(canteen) {
    if (canteenData.id === canteen.id) {
      setCanteenData({});
    } else {
      setCanteenData(canteen);
    }
  }

  function openEdit() {
    if (!canteenData.id) return notifyError(t("canteen.select_canteen_warning_1"));
    setOpenFormModal(true);
  }

  function openDelete() {
    if (!canteenData.id) return notifyError(t("canteen.select_canteen_warning_2"));
    setConfirm({ open: true });
  }

  return (
    <>
      <Box sx={{ minHeight: "calc( 100vh - 123px) " }}>
        {/* Page content */}
        <Container fluid>
          <BreadCrumbs pageName={t("canteen.management")} />
          {/* Table */}
          <Row>
            <div className="col">
              <Card
                style={{
                  backgroundColor: themMode === "dark" ? "#1F2A37" : "",
                }}
              >
                <CardHeader className={`border-0 bg-${themMode !== "dark" || "transparent"}`}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <Box>
                      <h3 className={`mb-0 text-${themMode === "light" ? "black" : "white"}`}>
                        {t("canteen.maqsf")}
                      </h3>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        gap: "10px",
                        paddingBottom: "10px",
                      }}
                    >
                      {all_permissions?.includes("Add canteen") && (
                        <Button
                          onClick={() => setOpenFormModal(true)}
                          className={`${themMode === "dark" && "dark-button-man"}`}
                          sx={{
                            color: "black",
                            backgroundColor: "#fff",
                            fontSize: "15px",
                            transition: ".4s",
                            display: "flex",
                            gap: "5px",
                            boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",

                            padding: "8px 10px",
                            borderRadius: "10px",
                            textTransform: "none",
                            "&:hover": {
                              backgroundColor: "#fff",
                              opacity: ".8",
                            },
                          }}
                        >
                          {t("canteen.add")}
                          <AddCircleIcon sx={{ color: "#71DCD4" }} />
                        </Button>
                      )}
                      {all_permissions?.includes("Update canteen") && (
                        <Button
                          onClick={openEdit}
                          className={`${themMode === "dark" && "dark-button-man"}`}
                          sx={{
                            color: "black",
                            backgroundColor: "#fff",
                            display: "flex",
                            gap: "5px",
                            fontSize: "15px",
                            transition: ".4s",
                            boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
                            padding: "8px 10px",
                            borderRadius: "10px",
                            textTransform: "none",
                            "&:hover": {
                              backgroundColor: "#fff",
                              opacity: ".8",
                            },
                          }}
                        >
                          {t("canteen.edit")}
                          <EditIcon />
                        </Button>
                      )}
                      {all_permissions?.includes("Delete canteen") && (
                        <Button
                          sx={{
                            color: "black",
                            backgroundColor: "#fff",
                            fontSize: "15px",
                            transition: ".4s",
                            boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
                            padding: "8px 10px",
                            borderRadius: "10px",
                            display: "flex",
                            gap: "5px",
                            textTransform: "none",
                            "&:hover": {
                              backgroundColor: "#fff",
                              opacity: ".8",
                            },
                          }}
                          className={`${themMode === "dark" ? "dark-button-man" : ""}`}
                          onClick={openDelete}
                        >
                          {t("canteen.delete")}
                          <DeleteIcon sx={{ color: "#C81E1E" }} />
                        </Button>
                      )}
                    </Box>
                  </Box>
                </CardHeader>
                {isLoading ? (
                  <Box sx={{ display: "flex", justifyContent: "center" }}>
                    <CircularProgress />
                  </Box>
                ) : canteens?.data?.data?.length > 0 ? (
                  <Table className={`align-items-center  table-${mode} table-flush`} responsive>
                    <thead
                      style={{
                        backgroundColor: themMode === "dark" ? "#374151" : "#F9FAFB",
                        color: "#6B7280",
                      }}
                    >
                      <tr>
                        <th
                          style={{
                            textAlign: "center",
                            padding: "20px 0px",
                            fontSize: "12px",
                            width: "8px",
                          }}
                        ></th>

                        {/* Canteen name */}
                        <th
                          style={{
                            textAlign: "center",
                            padding: "20px ",
                            fontSize: "12px",
                          }}
                          scope="col"
                        >
                          {t("canteen.name")}
                        </th>

                        {/* Created at */}
                        <th
                          style={{
                            textAlign: "center",
                            padding: "20px ",
                            fontSize: "12px",
                          }}
                          scope="col"
                        >
                          {t("canteen.created_at")}
                        </th>

                        {/* Updated at */}
                        <th
                          style={{
                            textAlign: "center",
                            padding: "20px ",
                            fontSize: "12px",
                          }}
                          scope="col"
                        >
                          {t("canteen.updated_at")}
                        </th>
                      </tr>
                    </thead>
                    {all_permissions?.includes("Canteens") && (
                      <tbody
                        style={{
                          backgroundColor: themMode === "dark" ? "#1F2A37" : "white",
                        }}
                      >
                        {canteens?.data?.data?.map((canteen, index) => (
                          <tr key={index}>
                            <td style={{ textAlign: "center" }}>
                              <Checkbox
                                onClick={() => handleCheck(canteen)}
                                checked={canteenData?.id === canteen.id}
                              />
                            </td>
                            <th style={{ textAlign: "center" }} scope="row">
                              {canteen?.name || "-"}
                            </th>
                            <td style={{ textAlign: "center" }}>{canteen?.created_at || "-"}</td>
                            <td style={{ textAlign: "center" }}>{canteen?.updated_at || "-"}</td>
                          </tr>
                        ))}
                      </tbody>
                    )}
                  </Table>
                ) : (
                  <EmptyContentMessage
                    onClick={() => {
                      if (all_permissions?.includes("Add canteen")) {
                        setOpenFormModal(true);
                      } else {
                        notifyError("You don't have permission to add.");
                      }
                    }}
                    buttonText={t("canteen.add")}
                  />
                )}

                {/* Pagination */}
                <Stack
                  direction="row"
                  sx={{
                    margin: "10px 0",
                    justifyContent: "center",
                    padding: "10px",
                    display: canteens?.data?.data?.length > 0 ? "flex" : "none",
                  }}
                  gap={2}
                  justifyContent={"center"}
                >
                  <Pagination
                    count={canteens?.data?.last_page || 1}
                    variant="outlined"
                    shape="rounded"
                    value={page}
                    onChange={(e, value) => setPage(value)}
                  />
                </Stack>
              </Card>
            </div>
          </Row>
        </Container>
      </Box>

      {/* Confirmation */}
      <CloseModel
        open={confirm.open}
        handleClose={handleClose}
        fun={DeleteCanteen}
        id={canteenData.id}
        page={page}
        mutateFun={() => {
          queryClient.invalidateQueries({
            queryKey: ["canteens"],
          });
        }}
        msg={t("user.deleteParent")}
        onSuccessFunction={() => {
          setCanteenData({});
        }}
      />

      {/* Create || Edit branch modal */}
      <GlobalModal
        modalHeaderTypography={
          canteenData?.id ? t("canteen.update_canteen") : t("canteen.create_canteen")
        }
        open={openFormModal}
        handleClose={() => {
          setOpenFormModal(false);
        }}
      >
        <CanteenForm
          handleClose={() => {
            setOpenFormModal(false);
          }}
          canteenId={canteenData?.id || null}
          branchId={canteenData?.branch_id || null}
          initialManagerId={canteenData?.manager_id || ""}
          canteenName={canteenData?.name || ""}
          setCanteenData={setCanteenData}
        />
      </GlobalModal>
    </>
  );
}

export default CanteensManagement;

/**
 * Edit Canteen
 * 1. Canteen id
 * 2. Branch id
 * 3. Manger id
 */

function CanteenForm({
  handleClose,
  branchId = null,
  canteenName = "",
  canteenId = "",
  initialManagerId = "",
  setCanteenData = null,
}) {
  const [canteenNameState, setCanteenNameState] = useState(canteenName);
  const [branchIdState, setBranchIdState] = useState(branchId ? branchId : "");
  const [managerId, setManagerId] = useState(initialManagerId);
  const { t } = useTranslation();

  // Get all managers
  const { data: managersData, isLoading: managersIsLoading } = useQuery({
    queryKey: ["managers"],
    queryFn: GetAllManagers,
  });

  // Get all Branches
  const { data: branchesData, isLoading: branchesIsLoading } = useQuery({
    queryKey: ["branches"],
    queryFn: GetAllBranches,
  });

  const queryClient = useQueryClient();
  const { mutate } = useMutation({
    mutationFn: branchId ? UpdateCanteen : AddCanteen,
    onSuccess: (data) => {
      queryClient.invalidateQueries({
        queryKey: ["canteens"],
      });
      notifySuccess(branchId ? t("canteen.updated_success") : t("canteen.added_success"));
      if (branchId) {
        setCanteenData((prev) => {
          return { ...prev, name: canteenNameState, manager_id: managerId };
        });
      }
      handleClose();
    },
    onError: (err) => {
      if (err.response) {
        const status = err.response.status;
        if (status === 422) {
          const errors = err.response.data.errors;
          const errorMessages = [];

          if (errors.name) {
            errorMessages.push(`Name: ${errors.name.join(", ")}`);
          }
          if (errors.manager_id) {
            errorMessages.push(`Manager: ${errors.manager_id.join(", ")}`);
          }

          errorMessages.forEach((message) => {
            notifyError(message);
          });
        } else {
          notifyError(err.message);
          err?.response?.data?.message && notifyError(err?.response?.data?.message);
        }
      } else {
        // console.log("Error", err.message);
      }
    },
  });

  const handleChangeSelectManager = (event) => {
    setManagerId(event.target.value);
  };

  const handleChangeSelectBranch = (event) => {
    setBranchIdState(event.target.value);
  };

  const onSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    const name = formData.get("name");
    const managerID = formData.get("manager_id");

    if (name.trim() === "" || managerID.trim() === "") {
      notifyError(t("canteen.required_fields"));
    }

    if (canteenId) {
      mutate({ formData, id: canteenId });
    } else {
      mutate(formData);
    }
  };

  useEffect(() => {
    if (canteenId && branchId && managerId) {
      setManagerId(managerId);
    }
  }, [branchId, managerId, canteenId]);

  return (
    <form onSubmit={onSubmit} className="py-4">
      <TextField
        label={t("canteen.name")}
        variant="outlined"
        size="small"
        required
        fullWidth
        className="mb-4"
        name="name"
        type="text"
        defaultValue={canteenId ? canteenNameState : ""}
        onChange={(event) => {
          setCanteenNameState(event.target.value);
        }}
      />

      {/* Managers */}
      <FormControl fullWidth className="mb-4">
        <InputLabel id="Manager">{t("canteen.manager")}</InputLabel>
        <Select
          labelId="Manager"
          id="selectManager"
          value={managerId ? managerId : ""}
          name="manager_id"
          label="Manager"
          onChange={handleChangeSelectManager}
          required
          disabled={managersIsLoading}
        >
          {managersData?.data?.map((manager) => (
            <MenuItem value={manager.id} key={manager.id}>
              {manager.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      {/* Branches */}
      <FormControl fullWidth className="mb-4">
        <InputLabel id="Branches">{t("canteen.branches")}</InputLabel>
        <Select
          labelId="Branches"
          id="selectManager"
          value={branchIdState ? branchIdState : ""}
          name="branch_id"
          label="Branches"
          onChange={handleChangeSelectBranch}
          required
          disabled={branchesIsLoading}
        >
          {branchesData?.data?.data?.map((branch) => (
            <MenuItem value={branch.id} key={branch.id}>
              {branch.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <Button type="submit" variant="contained" color="primary" fullWidth>
        {branchId ? t("canteen.update") : t("canteen.create")}
      </Button>
    </form>
  );
}
