import Index from "views/Index.js";
import Login from "views/examples/Login.js";
import StudentManagment from "pages/StudentManagment/StudentManagment";
import UserManagement from "pages/UserManagment/UserManagement";
import LogoutIcon from "@mui/icons-material/Logout";
import ParetnsManegment from "pages/ParentsManegment/ParetnsManegment";
import Roles from "pages/Roles/Roles";
import EquipmentManegment from "pages/EquipmentManegment/EquipmentManegment";
import Reports from "pages/Reports/Reports";
import Settings from "pages/Settings/Settings";
import BranchManagement from "pages/BranchManagement/BranchManagement";
import CanteensManagement from "pages/CanteensManagement/CanteensManagement";
import MachinesManagement from "pages/MachinesManagement/MachinesManagement";
import ForgetPassword from "pages/ForgotPassword/ForgotPassword";
import OTP from "pages/OTP/OTP";
import NewPassword from "pages/NewPassword/NewPassword";
import Profile from "pages/Profile/Profile";

const routes = [
  {
    path: "/index",
    name: "Dashboard",
    arName: "لوحة التحكم",
    component: <Index />,
    layout: "/dashboard",
    icon: "space_dashboard.svg",
    permission_key: "Dashboard",
  },
  {
    path: "/branch-management",
    name: "Branches Management",
    arName: "إدارة الفروع",
    icon: "branch.svg",
    component: <BranchManagement />,
    layout: "/dashboard",
    permission_key: "Branches",
  },
  {
    path: "/canteens-management",
    name: "Canteens Management",
    arName: "إدارة كانتين",
    icon: "shop.svg",
    component: <CanteensManagement />,
    layout: "/dashboard",
    permission_key: "Canteens",
  },
  {
    path: "/machines-management",
    name: "Machines Management",
    arName: "إدارة الآلات",
    icon: "vending-machine.png",
    component: <MachinesManagement />,
    layout: "/dashboard",
    permission_key: "Machines",
  },
  {
    path: "/student-management",
    name: "Student Management",
    arName: "ادارة الطلاب",
    icon: "child_care.svg",
    component: <StudentManagment />,
    layout: "/dashboard",
    permission_key: "Student profiles",
  },
  {
    path: "/user-management",
    name: "User Management",
    arName: "ادارة المستخدمين",
    icon: "manage_accounts.svg",
    component: <UserManagement />,
    layout: "/dashboard",
    permission_key: "All users",
  },
  {
    path: "/parent-management",
    name: "Parents Management",
    arName: "ادارة اولياء الامور",
    icon: "family_restroom.svg",
    component: <ParetnsManegment />,
    layout: "/dashboard",
    permission_key: "All parents",
  },
  {
    path: "/roles",
    arName: "ادوار",
    name: "Roles",
    icon: "frame_person.svg",
    component: <Roles />,
    layout: "/dashboard",
    permission_key: "Create roles",
  },
  {
    path: "/equipment-management",
    arName: "إدارة الماليات",
    name: "Finance Management",
    icon: "home_repair_service.svg",
    component: <EquipmentManegment />,
    layout: "/dashboard",
    permission_key: "Charge balance",
  },
  // {
  //   path: "/select-boxes",
  //   arName: "أختيارات متعدده",
  //   name: "Select Boxes",
  //   icon: "check_box.svg",
  //   component: <SelectBoxes />,
  //   layout: "/dashboard",
  //   permission_key: "Select boxes",
  // },
  {
    path: "/report",
    arName: "التفارير",
    name: "Reports",
    icon: "lab_profile.svg",
    component: <Reports />,
    layout: "/dashboard",
    permission_key: "Charge status",
  },
  {
    path: "/settings",
    arName: "الاعدادات",
    name: "Settings",
    icon: "side-settings.svg",
    component: <Settings />,
    layout: "/dashboard",
    permission_key: "Settings",
  },
  {
    path: "/profile",
    name: "Profile",
    arName: "الملف الشخصي",
    component: <Profile />,
    layout: "/dashboard",
    icon: "profile.png",
    permission_key: "Dashboard",
  },
  {
    path: "/login",
    type: "mui",
    arName: "تسجيل الخروج",
    name: "Logout",
    logout: true,
    icon: <LogoutIcon sx={{ marginLeft: "0x", marginRight: "12px" }} color="error" />,
    component: <Login />,
    layout: "/auth",
    permission_key: "Logout",
  },
  {
    path: "/forgot-password",
    arName: "هل نسيت كلمة السر",
    name: "Forgot Password",
    logout: true,
    icon: null,
    component: <ForgetPassword />,
    layout: "/auth",
    permission_key: null,
  },
  {
    path: "/forgot-password/otp",
    arName: "كلمة مرور لمرة واحدة",
    name: "OTP",
    logout: true,
    icon: null,
    component: <OTP />,
    layout: "/auth",
    permission_key: null,
  },
  {
    path: "/forgot-password/new-password",
    arName: "كلمة مرور جديدة",
    name: "New password",
    logout: true,
    icon: null,
    component: <NewPassword />,
    layout: "/auth",
    permission_key: null,
  },
  // {
  //   path: "/user-profile",
  //   name: "User Profile",
  //   icon: "ni ni-single-02 text-yellow",
  //   component: <Profile />,
  //   layout: "/dashboard",
  // },
  // {
  //   path: "/icons",
  //   name: "Icons",
  //   icon: "ni ni-planet text-blue",
  //   component: <Icons />,
  //   layout: "/dashboard",
  // },
  // {
  //   path: "/maps",
  //   name: "Maps",
  //   icon: "ni ni-pin-3 text-orange",
  //   component: <Maps />,
  //   layout: "/dashboard",
  // },
  // {
  //   path: "/tables",
  //   name: "Tables",
  //   icon: "ni ni-bullet-list-67 text-red",
  //   component: <Tables />,
  //   layout: "/dashboard",
  // },

  // {
  //   path: "/register",
  //   name: "Register",
  //   icon: "ni ni-circle-08 text-pink",
  //   component: <Register />,
  //   layout: "/auth",
  // },
];
export default routes;
